import { makeStyles } from '@material-ui/styles';
import React, { CSSProperties } from 'react';
import { I18n } from '../../../../Locales/I18nService';
import { Assets } from '../../../assets/Assets';
import { ButtonVariant, CustomButton, CustomButtonProps } from './CustomButton';

export const DeleteButton = (props: Partial<CustomButtonProps>) => {
    return <CustomButton {...props} icon={<Assets.IconDelete />} variant={ButtonVariant.Danger} />;
};

export const ButtonPrevious = (props: CustomButtonProps) => {
    const style: CSSProperties = { width: 140, ...props.styles?.root };

    const classes = makeStyles({ root: { '&:hover svg': { fill: '#FFFFFF' } } })();

    return (
        <CustomButton
            title={I18n.get('COMMON_PREVIOUS')}
            icon={<Assets.IconArrowPrevious />}
            fullWidth
            {...props}
            styles={{ ...props.styles, root: style }}
            classes={{ root: classes.root }}
        />
    );
};

export const ButtonNext = (props: CustomButtonProps) => {
    const style: CSSProperties = { width: 140, ...props.styles?.root };
    return (
        <CustomButton
            endRowIcon={<IconArrow style={props.styles?.iconEnd} />}
            title={I18n.get('COMMON_NEXT')}
            fullWidth
            variant={ButtonVariant.Blue}
            {...props}
            styles={{ root: style, content: props.styles?.content }}
        />
    );
};

export const ButtonSave = (props: CustomButtonProps) => {
    const style: CSSProperties = { width: '100%', ...props.styles?.root };
    return (
        <CustomButton
            title={I18n.get('COMMON_SAVE')}
            variant={ButtonVariant.Blue}
            {...props}
            styles={{ root: style }}
        />
    );
};

const IconArrow = ({ style }: React.SVGProps<any>) => {
    return (
        <svg width="9" height="16" viewBox="0 0 9 16" fill="#fff" style={style}>
            <path d="M.383 15.538a.65.65 0 0 0 .865.05l.058-.05 7.382-7.382L1.306.773a.652.652 0 0 0-.974.866l.05.057 6.46 6.46-6.46 6.459a.653.653 0 0 0-.05.866z" />
        </svg>
    );
};
