import { ApiRequest } from '../XHR/ApiRequest';
import { Constants } from '../../Constants';
import { ApiUrls } from '../../Models/ApiUrls';
import { AuthenticationResponse, RefreshRequest } from '../../Models/Authentication/AuthResponse';


export class AuthApi {
    public static async refresh(refreshToken: string): Promise<AuthenticationResponse> {
        const body: RefreshRequest = {
            refresh_token: refreshToken,
            client_id: Constants.CLIENT_ID,
            client_secret: Constants.CLIENT_SECRET,
        };
        return ApiRequest.post<AuthenticationResponse>(ApiUrls.REFRESH, body,
            { noCheckError: true, noAuth: true }
        );
    }
}

