import * as ReactSvg from 'react-svg-pan-zoom';

export type GestureEvent<T = any> =
    | React.MouseEvent<T>
    | React.ChangeEvent<T>
    | React.TouchEvent<T>
    | React.FormEvent<T>
    | React.DragEvent<T>;

export type SvgPanZoomEvent = ReactSvg.ViewerMouseEvent<any> | ReactSvg.ViewerTouchEvent<any>;

export const stopGesture = (e: GestureEvent) => e.stopPropagation();
export const useStopGesture = (): React.DOMAttributes<any> => {
    return {
        onClick: stopGesture,
        onDoubleClick: stopGesture,
        onMouseMove: stopGesture,
        onTouchEnd: stopGesture,
        onTouchMove: stopGesture,
    };
};
