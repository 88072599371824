import { Theme } from '@material-ui/core/styles';
import React from 'react';

import { CustomDialogContainer } from '../CustomDialogContainer';
import { CustomToastContainer } from '../CustomToastContainer';
import { MuiApp } from '../MuiApp';
import { ReduxApp } from '../ReduxApp';
import { IntlWrapper } from './IntlWrapper';
import { EssentialsActionsGate } from './MountActionsGate/EssentialsActionsGate';
import { PolQueryAuthenticationGate } from './MountActionsGate/PolQueryAuthenticationGate';

export interface AppWrapperProps {
    readonly store: any;
    readonly persistor: any;
    readonly content?: React.ReactNode;

    //* Array of Promises to execute on mount
    readonly mountActions?: Array<() => Promise<boolean>>;
    //* Automatically setup react-intl & intl
    //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
    readonly setupIntl?: () => Promise<boolean>;
    //* Should trigger 'BaseActions.triggerEssentialReload'
    readonly withEssentialReload: () => boolean;

    //* Mui Theme
    readonly getMuiTheme: () => Theme;
}

export const AppWrapper: React.FC<AppWrapperProps> = ({
    store,
    persistor,
    content,
    children,
    mountActions = [],
    setupIntl,
    withEssentialReload = () => true,
    getMuiTheme,
}) => (
    <MuiApp getMuiTheme={getMuiTheme}>
        <ReduxApp store={store} persistor={persistor}>
            <PolQueryAuthenticationGate>
                <EssentialsActionsGate
                    actions={mountActions}
                    setupIntl={setupIntl}
                    withEssentialReload={withEssentialReload()}>
                    <IntlWrapper>
                        {children ?? content}
                        <CustomToastContainer />
                        <CustomDialogContainer />
                    </IntlWrapper>
                </EssentialsActionsGate>
            </PolQueryAuthenticationGate>
        </ReduxApp>
    </MuiApp>
);
