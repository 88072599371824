import { useSelector } from 'react-redux';
import { AppState, useMe } from '../../../Configs/PolStore';
import { TokenType } from '../../../Models/Authentication/AuthResponse';
import { UserTypeDto } from '../../../Models/Users/Me';
import { MeState } from '../../../Services/Me/MeReducer';

export const useIsVendeur = (): boolean => {
    const { token_type } = useSelector((state: AppState) => state.Authentication);
    const me: MeState = useMe();
    return token_type === TokenType.CrmAXStore || me.data?.type === UserTypeDto.BoUserAsStore;
};
