import { ApiDataResponse } from '../ApiData';

export type UserSummary = {
    id: string;
    first_name: string;
    last_name: string;
};

export enum UserTypeDto {
    Customer = 'Customer',
    StmacStore = 'StmacStore',
    BoUserAsCustomer = 'BoUserAsCustomer',
    BoUserAsStore = 'BoUserAsStore',
}

export interface CustomerPropertiesDto {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
}

export interface StmacStorePropertiesDto {
    id: string;
    as_customer: CustomerPropertiesDto;
}

export interface BackOfficeUserAsStorePropertiesDto {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
}

export interface BackOfficeUserAsCustomerPropertiesDto {
    id: string;
    first_name: string;
    last_name: string;
}
export interface MeDto {
    type: UserTypeDto;
    customer_properties?: CustomerPropertiesDto; // GET FROM POST MESSAGE
    stmac_store_properties?: StmacStorePropertiesDto;
    bo_user_as_store_properties?: BackOfficeUserAsStorePropertiesDto;
    bo_user_as_customer_properties?: BackOfficeUserAsCustomerPropertiesDto;
}

//* RESPONSE
export type MeResponse = ApiDataResponse<MeDto>;
