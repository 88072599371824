import { AppRoutes } from './Configs/AppRoutes';

export class Constants {
    //public static SHOW_ONBOARDING_STORAGE_KEY = 'pol-showOnboarding';//OBSOLETE IN V3

    //* STORAGE KEY
    public static AUTH_STORAGE_KEY = 'pol-auth';
    public static DEVICE_ID_STORAGE_KEY = 'pol-deviceId';
    public static BO_CLEAR_TOKEN: string = 'BO_ClearToken';

    //* API
    public static CLIENT_ID = 'CLICANDPOSE_FRONT';
    public static CLIENT_SECRET = 'Ideine';
    private static API_BASE_URL = '__API_HOST__';
    private static API_BASE_URL_DEV = 'https://clic-and-pose-api-dev.azurewebsites.net';
    //private static API_BASE_URL_DEV = 'http://localhost:5001';

    public static isLocalDev = process.env.NODE_ENV === 'development';
    public static VERSION_NUMBER = Constants.isLocalDev ? '3.0.0' : '__BUILD_ID__';
    public static ENVIRONMENT = Constants.isLocalDev ? 'localdev' : '__ENVIRONMENT__';

    public static isStMacProd = () => Constants.ENVIRONMENT === 'prod';
    public static isIdeineProd = () => !['dev', 'test', 'beta', 'preprod'].includes(Constants.ENVIRONMENT);

    public static QUESTION_PRODUIT_COUNT: number = 4;
    public static QUESTION_SUGGESTION_PRODUIT_COUNT: number = 100; //NO LIMIT

    public static getRoot = () => (Constants.isLocalDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL);

    public static LOGS = {
        elasticSearchUrl: `${Constants.getRoot()}/api/v1/logwebproxy/sendlog`,
        elasticSearchIndex: Constants.isLocalDev ? 'clic-and-pose-front-web-local' : '__ELASTIC_SEARCH_INDEX__',
        apiKey: '9813d35f-95e0-4a40-9db5-6117ec02055c',
    };

    public static isCrmLocation = (pathname: string) => pathname.startsWith(AppRoutes.CRM_LANDING);

    public static FASTERIZE_MEDIA_URL = `/fstrz/r/s/`;
    private static USE_FASTERIZE_MEDIA_URL_STRING = Constants.isLocalDev ? 'FALSE' : '__USE_FASTERIZE_MEDIA_URL__';
    public static USE_FASTERIZE_MEDIA_URL = Constants.USE_FASTERIZE_MEDIA_URL_STRING === 'TRUE';

    public static USE_DATADOG_RUM = Constants.isLocalDev ? 'FALSE' : '__USE_DATADOG_RUM__';
    public static isDatadogRumEnabled = () => Constants.USE_DATADOG_RUM === 'TRUE';

    //#region //* STMAC

    public static STMAC_CDN_IMAGE_URL_DEV = 'https://media-uat.cdn.saint-maclou.com';
    public static STMAC_CDN_IMAGE_URL = Constants.isLocalDev
        ? Constants.STMAC_CDN_IMAGE_URL_DEV
        : '__STMAC_CDN_IMAGE_URL__';

    public static USE_STMAC_CDN_IMAGE_NEW_URL = false; // Constants.USE_STMAC_CDN_IMAGE_URL_NEW_FORMAT === 'TRUE';

    public static STMAC_URL_DEV = 'https://front-uat.saint-maclou.com';
    //public static STMAC_URL_DEV = 'https://stmaclou-react-dev.datasolution.site';
    //public static STMAC_URL_DEV = 'https://pol-stmaclou.datasolution.site';
    public static STMAC_URL_PROD = 'https://www.saint-maclou.com';

    public static STMAC_FRAME_ID = 'StmacViewFrame-po';
    public static STMAC_URL = Constants.isLocalDev ? Constants.STMAC_URL_DEV : '__STMAC_URL__';
    public static STMAC_LOGIN_URL = `${Constants.STMAC_URL}/simulateur-pose/connexion`;
    public static STMAC_IFRAME_RESPONSE_TIMEOUT_MS = Constants.isLocalDev ? 5000 : 30000;

    public static STMAC_CATALOG_BASE_URL_DEV = 'https://pol-stmaclou.datasolution.site';
    public static STMAC_CATALOG_BASE_URL = Constants.isStMacProd()
        ? Constants.STMAC_URL_PROD
        : Constants.isLocalDev
            ? Constants.STMAC_CATALOG_BASE_URL_DEV
            : '__STMAC_CATALOG_URL__';
    public static STMAC_CATALOG_URL = `${Constants.STMAC_CATALOG_BASE_URL}/simulateur-pose/produits/revetement-sol`;

    //#endregion

    public static GTM_ID = 'GTM-W9X3KST';

    public static STMAC_BOOK_APPOINTMENT_URL = 'https://www.saint-maclou.com/appointment-scheduling?origine=clicetpose';
    public static STMAC_CGV_URL = 'https://www.saint-maclou.com/entreprise/conditions-generales-de-vente';
    public static STMAC_CGP_URL =
        'https://www.saint-maclou.com/entreprise/conditions-generales-de-prestations-de-services';
}
