import { withCurrentProjectLogs } from '../Configs/CurrentProjectContext';
import { ObjectUtils } from '../Helpers/ObjectUtils';
import { AnalyticsLogger } from '../Services/Analytics/AnalyticsLogger';
import { LoglevelLogger } from '../Services/Analytics/LoglevelLogger';
import { LogLevelType } from '../Services/Analytics/LogLevelType';
import { LogMessageFields } from '../Services/Analytics/LogMessageModel';

interface LogAnalyticsParameters {
    level?: LogLevelType;
    message?: string;
    value?: any;
    data?: any;
    body?: any;
    type?: string;
}

export class Logger {
    public static LoggerId = 'pol-devDebug';
    public static LoggerMapId = 'pol-devDebugMap';

    public static isLogEnabled = (): boolean => Boolean(localStorage.getItem(this.LoggerId));

    public static log(message: string, data: any = '') {
        Logger.isLogEnabled() && console.log(message, ObjectUtils.clone(data));
    }

    public static debug(message: string, data?: any) {
        Logger.isLogEnabled() && console.log(message, ObjectUtils.clone(data));
        LoglevelLogger.info(message, data);
    }

    public static error(message: string, data?: any) {
        Logger.isLogEnabled() && console.log(message, ObjectUtils.clone(data));
        LoglevelLogger.error(message, data);
    }

    public static logInfo(parameters: LogAnalyticsParameters) {
        Logger.isLogEnabled() && console.log(ObjectUtils.clone(parameters));
        this.logAnalytics({ level: LogLevelType.Information, ...parameters });
    }

    public static logAnalytics(parameters: LogAnalyticsParameters) {
        const { message = '', value = {}, data = {}, body, level = LogLevelType.Error, type } = parameters;
        const content: LogMessageFields = {
            data,
            error: value?.error ?? value,
            route: value?.errorResponse?.config?.url,
            CustomMessage: message,
            Message: value?.message ?? value.error?.message,
            Stack: value?.stack ?? value?.errorResponse?.stack ?? value.error?.stack,
            LogLevel: level,
            body,
            type,
            ...withCurrentProjectLogs(),
        };
        Logger.isLogEnabled() && console.log(parameters.message, content);
        AnalyticsLogger.send(content);
    }
}
