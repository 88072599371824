import { ApiDataResponse } from '../ApiData';

export enum TokenType {
    Bearer = 'Bearer',
    CrmAXStore = 'CrmAXStore',
    StmacJWT = 'StmacJWT',
}
export interface RefreshRequest {
    refresh_token: string;
    client_id: string;
    client_secret: string;
}

export interface AuthenticationResult {
    access_token: string;
    refresh_token: string;
    token_type: string;
}

export type AuthenticationResponse = ApiDataResponse<AuthenticationResult>;
