import { datadogRum } from '@datadog/browser-rum';

export class Datadog {
    public static initialize = (env: string, version: string): void => {
        datadogRum.init({
            applicationId: 'd199226f-f9ac-46af-b794-03de15f36700',
            clientToken: 'pub24fa660e3e7e10ef615907dd258e9359',
            site: 'datadoghq.eu',
            service: 'clic-and-pose',
            env,
            version,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 10,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
        console.log('running script : datadogrum', { env, version });
    }
}
