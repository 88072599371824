import { StmacPostMessage } from './StmacPostMessage';

export interface StmacClient {
    readonly id: string;
    readonly token: string;
    // ISO Date string
    readonly token_expiry: string;
    readonly first_name: string;
    readonly last_name: string;
    readonly email: string;
}

export interface StmacLoginMessage extends StmacPostMessage {
    readonly mode: string;
    readonly client: StmacClient; //* if isPostMessageUser | isPostMessageReGetUser | isPostMessageResSetUser
    readonly success: boolean;
}

export class StmacLoginMessages {
    public static GET_USER_REQUEST_ID = 'POL_GET_USER_REQUEST_ID';
    public static GET_SET_TOKEN_ID = 'POL_GET_SET_TOKEN_ID';

    public static isPostMessageReady = (type: string) => type === 'embed:ready';

    //handle only in Dialog
    public static isPostMessageUserAuthenticated = (type: string) => type === 'user_authenticated';

    public static getCurrentUser = () => ({
        type: 'get_user',
        tid: StmacLoginMessages.GET_USER_REQUEST_ID,
    });

    public static isPostMessageResGetUser = (type: string) => type === 'res:get_user';

    public static getSetToken = (token: string) => ({
        type: 'set_user',
        tid: StmacLoginMessages.GET_SET_TOKEN_ID,
        token,
    });

    public static isPostMessageResSetUser = (type: string) => type === 'res:set_user';
}
