import { ApiRequest } from '../XHR/ApiRequest';
import { ApiUrls } from '../../Models/ApiUrls';
import { AuthenticationResponse } from '../../Models/Authentication/AuthResponse';
import { CRMQueryParameter } from '../../Models/CRM/CRM';

export class CRMApi {

  public static authenticate = (bodyRequest: CRMQueryParameter): Promise<AuthenticationResponse> => {
    return ApiRequest.post<AuthenticationResponse>(ApiUrls.AUTHENTICATE, bodyRequest, { noAuth: true });
  };
}
