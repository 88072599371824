
import { StoreProvider } from '../../Configs/StoreProvider';
import { MeDto, MeResponse } from '../../Models/Users/Me';
import { MeApi } from '../Api/MeApi';
import { MeActions } from './MeActions';

const dispatch = (action: any) => StoreProvider.dispatch(action);

export class MeService {
    public static getMyInfo = async (): Promise<MeResponse | any> => {
        try {
            await dispatch(MeActions.setInfo(undefined));

            const response: MeResponse = await MeApi.getMyInfo();
            await MeService.setMyInfo(response.data);

            return Promise.resolve(response);
        } catch (error) {
            await dispatch(MeActions.setInfo(undefined));
            return Promise.reject(error);
        }
    };

    public static setMyInfo = async (meDto: MeDto): Promise<MeDto> => {
        try {
            await dispatch(MeActions.setInfo(meDto));
            return Promise.resolve(meDto);
        } catch (error) {
            await dispatch(MeActions.setInfo(undefined));
            return Promise.reject(error);
        }
    };
}
