import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { Message } from '../../../../../Locales/I18nService';
import { BaseDialog } from '../../../../../Plugins/FloorPlan/Widgets/BaseDialog';
import { ButtonVariant, CustomButton } from '../../../../Components/Views/CustomButton/CustomButton';

export type YesNoDialogProps = {
    open?: boolean;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    onClose?: VoidFunction;
    leftAction?: React.ReactNode;
    onClickYes: (setValidating: (validating: boolean) => void) => void;
    rightAction?: React.ReactNode;
    onClickNo: VoidFunction;
    yesHighlight?: boolean;
    noHighlight?: boolean;
    enableCloseOutside?: boolean;
    header?: React.ReactNode;
    body?: React.ReactNode;

    styles?: { rightAction?: CSSProperties };
};

export const YesNoChoiceDialog = ({
    title,
    subtitle,
    onClose,
    leftAction,
    onClickYes,
    rightAction,
    onClickNo,
    yesHighlight,
    noHighlight,
    enableCloseOutside,
    styles,
}: YesNoDialogProps) => {
    const [validating, setValidating] = React.useState(false);

    const onValidate = () => onClickYes(setValidating);

    const onCloseDialog = () => {
        if (!validating) {
            onClose?.();
        }
    };

    return (
        <BaseDialog title={title} subtitle={subtitle} onClose={onCloseDialog} enableCloseOutside={enableCloseOutside}>
            <Content>
                <CustomButton
                    title={leftAction || <Message id="COMMON_YES" />}
                    styles={{ root: BtnStyle }}
                    variant={yesHighlight ? ButtonVariant.Blue : ButtonVariant.BorderedBlue}
                    onClick={onValidate}
                    isBusy={validating}
                />
                <CustomButton
                    title={rightAction || <Message id="COMMON_NO" />}
                    styles={{ root: { ...BtnStyle, ...styles?.rightAction } }}
                    variant={noHighlight ? ButtonVariant.Blue : ButtonVariant.BorderedBlue}
                    disabled={validating}
                    onClick={(e) => {
                        e?.stopPropagation();
                        onClickNo();
                    }}
                />
            </Content>
        </BaseDialog>
    );
};

const Content = styled('div')({ marginTop: '37px', display: 'flex', gap: '12px' });
const BtnStyle: CSSProperties = { flex: 1 };
