import { Logger } from '../../Errors/Logger';
import { AuthenticationResult } from '../../Models/Authentication/AuthResponse';
import * as AuthenticationTypes from './typings/AuthenticationActionTypes';

export class AuthenticationActions {
    public static logout = (reason?: string): AuthenticationTypes.LogoutAction => ({
        type: AuthenticationTypes.AuthActionType.LOG_OUT,
        payload: { reason },
    });

    static setInitialized = (initialized: boolean, reason: string): AuthenticationTypes.SetInitializedAction => ({
        type: AuthenticationTypes.AuthActionType.SET_INITIALIZED,
        payload: { initialized, reason },
    });

    public static setAuthenticated = ({
        isAuthenticated,
        authorization,
        initialized,
        initializedReason,
    }: {
        isAuthenticated: boolean;
        authorization: AuthenticationResult;
        initialized: boolean;
        initializedReason?: AuthenticationTypes.InitializedReason;
    }): AuthenticationTypes.SetAuthenticatedAction => {
        Logger.debug('POL setAuthenticated', { isAuthenticated, authorization, initializedReason });

        return {
            type: AuthenticationTypes.AuthActionType.SET_AUTHENTICATED,
            payload: { isAuthenticated, authorization, initialized, initializedReason },
        };
    };

    /**
     * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
     * @param {Object} authorization - the token like Bearer XXXX
     * @returns {Promise<Object>} result - the result received
     */
    public static setAuthorization = (
        authorization: AuthenticationResult,
        reason: AuthenticationTypes.InitializedReason
    ) => {
        return AuthenticationActions.setAuthenticated({
            isAuthenticated: Boolean(authorization),
            authorization,
            initialized: true,
            initializedReason: reason,
        });
    };

    public static clearAuthorizationHeader = (): AuthenticationTypes.SetAuthenticatedAction => ({
        type: AuthenticationTypes.AuthActionType.SET_AUTHENTICATED,
        payload: {
            isAuthenticated: false,
            authorization: {
                access_token: '',
                refresh_token: '',
                token_type: '',
            },
            initialized: true,
            initializedReason: AuthenticationTypes.InitializedReason.CLEAR_AUTHORIZATION,
        },
    });

    //* REFRESH
    /**
     * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
     * @param {Object} result - The result of the successful refresh
     * @param {String} result.access_token
     * @param {String} result.refresh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result
     */
    static refreshSuccess = (result: AuthenticationResult) => {
        return AuthenticationActions.setAuthenticated({
            isAuthenticated: true,
            authorization: result,
            initialized: true,
            initializedReason: AuthenticationTypes.InitializedReason.REFRESH_SUCCESS,
        });
    };

    /**
     * Dispatches the email refresh failure
     * @throws {Promise<Error>} the error received
     */
    static refreshFail = (error: any) => {
        return AuthenticationActions.setAuthenticated({
            isAuthenticated: false,
            authorization: {
                access_token: '',
                refresh_token: '',
                token_type: '',
            },
            initialized: true,
            initializedReason: AuthenticationTypes.InitializedReason.REFRESH_FAILED,
        });
    };

    /**
     * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
     * @param {Object} result - The result of the successful login
     * @param {String} result.access_token
     * @param {String} result.refersh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result received
     */
    static loginSuccess = (auth: AuthenticationResult) => (dispatch: any) => {
        // const authorization = AuthUtils.getAuthorizationFromToken(auth.access_token);

        dispatch(
            AuthenticationActions.setAuthenticated({
                isAuthenticated: true,
                authorization: auth,
                initialized: true,
                initializedReason: AuthenticationTypes.InitializedReason.LOGIN_SUCCESS,
            })
        );
        return Promise.resolve(auth);
    };

    /**
     * Dispatches the email login failure with the errror received
     * @param {Error} error
     * @throws {Promise<Error>} the error received
     */
    static loginFail = (error: any) => {
        return AuthenticationActions.setAuthenticated({
            isAuthenticated: false,
            authorization: {
                access_token: '',
                refresh_token: '',
                token_type: '',
            },
            initialized: true,
            initializedReason: AuthenticationTypes.InitializedReason.LOGIN_FAILED,
        });
    };
}
