import { styled } from '@material-ui/styles';
import React, { CSSProperties } from 'react';

import { CustomTextField, CustomTextFieldProps } from '../../../Components/Views/CustomTextField/CustomTextField';

export interface InputTextFieldProps extends CustomTextFieldProps {
    value: string;
    error: boolean;
    label?: React.ReactNode;
    description?: React.ReactNode;
}

export const InputTextField = ({ value, error, label, description, ...rest }: InputTextFieldProps) => {
    return (
        <Container>
            <Label>{label}</Label>
            <CustomTextField
                {...rest}
                label={description}
                error={error}
                value={value}
                styles={{ input: InputStyle, label: DescriptionStyle }}
            />
        </Container>
    );
};

const Container = styled('div')({ fontFamily: 'Avenir' });
const Label = styled('div')({
    display: 'flex',
    fontSize: '14px',
    fontWeight: 700,
    marginBottom: '8px',
});

const DescriptionStyle: CSSProperties = {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: 350,
    marginBottom: '10px',
};

const InputStyle: CSSProperties = {
    fontSize: '16px',
    fontWeight: '350',
    borderRadius: '0px',
    minHeight: '44px',
    paddingTop: '5px',
    paddingBottom: '5px',
};
