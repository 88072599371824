import React from 'react';

import { browserHistory } from './Configs/browserHistory';
import { AppRoutes } from './Configs/AppRoutes';
import { Constants } from './Constants';

import { Setup, SetupInitialized } from './Setup';
import { AppRouter } from './Web/Components/Templates/AppRouter/AppRouter';
import { AppWrapper } from './Web/Components/Templates/Wrappers/AppWrapper';
import { DebugProvider } from './Web/Context/Debug/DebugContext';
import { PlatformContextProvider } from './Web/Context/PlatformContext/PlatformContext';
import { StmacLoginViewGate } from './Web/Pages/Auth/StmacLoginViewGate';
import { ThemeMuiProvider } from './Web/Themes/ThemeMuiProvider';

const { storeConfig }: SetupInitialized = Setup.initialize();

export const Application: React.FC = () => {
    return (
        <DebugProvider>
            <AppWrapper
                store={storeConfig.store}
                persistor={storeConfig.persistor}
                getMuiTheme={() => ThemeMuiProvider.provideTheme()}
                withEssentialReload={() => !Constants.isCrmLocation(window.location.pathname)}>
                <PlatformContextProvider>
                    <StmacLoginViewGate isAuthenticatedFromUrl={Constants.isCrmLocation(window.location.pathname)}>
                        <AppRouter
                            browserHistory={browserHistory}
                            routes={AppRoutes.getRoutes()}
                            defaultRoot={AppRoutes.DEFAULT_ROUTE}
                        />
                    </StmacLoginViewGate>
                </PlatformContextProvider>
            </AppWrapper>
        </DebugProvider>
    );
};
