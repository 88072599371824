import { StringKey } from '../Locales/fr';
import { I18n } from '../Locales/I18nService';
import { ApiErrorCodes } from '../Models/ErrorCodes';
import { AuthHelper } from '../Services/Authentication/AuthHelper';
import { AuthService } from '../Services/Authentication/AuthService';
import { ApiError, HttpStatusCode, toApiError } from '../Services/XHR/ApiError';
import { Toast } from '../Web/Services/ToastService';
import { Logger } from './Logger';


const logError = (logMessage: string, customHttpErrors: ApiError, dataError: any) => {
    Logger.logAnalytics({
        message: `${logMessage}`,
        value: { ...customHttpErrors, dataError },
    });
};

export const handleError = async (error: any): Promise<boolean> => {
    const customHttpErrors = toApiError(error);

    const { isApiError, httpStatusCode, error_code, checkError }: ApiError = customHttpErrors;

    const dataError = {
        log_level: 'error',
        timestamp: new Date(),
        context: { userAgent: navigator.userAgent, location: window.location },
        error,
    };

    if (httpStatusCode === HttpStatusCode.Unauthorized) {
        if (customHttpErrors.error_message === ApiErrorCodes.TOKEN_IN_FUTURE) {
            //* customer or store
            const isCustomer = AuthHelper.isCustomer();
            Toast.showError({ content: I18n.get(isCustomer ? 'ERROR_CUSTOMER_TOKEN_IN_FUTUR' : 'ERROR_STORE_TOKEN_IN_FUTUR') });
        } else {
            Toast.showError({ content: I18n.get('PermissionDialog_NotAuthenticated') });
        }
        await AuthService.removeAuthorizationToken();

        //* il n'y pas d'accueil ou d'écran de connection
        //* pour se logguer si le token est expiré
        return Promise.resolve(true);
    }

    if (httpStatusCode === HttpStatusCode.BadRequest) {
        logError('POL: handleError BadRequest', customHttpErrors, dataError);
    }

    if (httpStatusCode === HttpStatusCode.Forbidden) {
        logError('POL: handleError Forbidden', customHttpErrors, dataError);
        if (customHttpErrors.error_message === ApiErrorCodes.UPDATE_NOT_ALLOWED) {
            Toast.showError({ content: I18n.get('ERROR_UPDATE_UNAUTHORIZED') });
        } else {
            Toast.showError({ content: I18n.get('Error_FORBIDDEN') });
        }
        return Promise.resolve(true);
    }

    if (isApiError && checkError) {
        logError('POL: handleError', customHttpErrors, dataError);

        //* let message = 'An error occurred with the server';
        const message =
            I18n.getValue(`Error_${error_code}` as StringKey) ||
            error_code ||
            //errorMessage || //* can be generic error so not usable
            I18n.get(`COMMON_ERROR_MESSAGE` as StringKey) ||
            //* default message in FR 🇫🇷
            "Quelque chose ne s'est pas passé comme prévu, merci de réessayer.";

        Toast.showError({ content: message });
    }

    return Promise.resolve(true);
};

/**
 * Create a custom error handler, that show a detailled error if needed.
 * @param showDetailledError
 * @returns
 */
export const createCustomErrorHandler =
    (showDetailledError: boolean = false) =>
        (error: any) => {
            if (error.isApiError) {
                const apiError = error as ApiError;
                const errorTitle: string = showDetailledError
                    ? Boolean(apiError.error_code)
                        ? I18n.format('COMMON_ERROR_TITLE', { code: apiError.error_code })
                        : apiError.error_code
                            ? I18n.format('COMMON_ERROR_TITLE', { code: apiError.httpStatusCode.toString() })
                            : I18n.get('Toast_TitleError')
                    : I18n.get('Toast_TitleError');

                const errorMessage: string = showDetailledError
                    ? apiError.error_message || I18n.get('COMMON_ERROR_MESSAGE')
                    : I18n.get('COMMON_ERROR_MESSAGE');

                Toast.showError({ title: errorTitle, content: errorMessage, duration: 5000 });
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        };

export const wrapError = async <TResult>(
    execAction: () => Promise<TResult>,
    execParams?: { errorHandler: (error: any) => Promise<boolean> }
): Promise<TResult | any> => {
    const { errorHandler } = execParams || {};
    try {
        const result: TResult = await execAction();
        return result;
    } catch (error) {
        //* error CAN BE : RESULT of onRequestFailed
        const errorHandled = await errorHandler?.(error) || false;
        if (!errorHandled && !await handleError(error)) {
            throw error;
        }
    }
};
