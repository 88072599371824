import 'loaders.css/loaders.min.css';

import { makeStyles, styled } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import Loader, { LoaderType } from 'react-loaders';
import classNames from 'classnames';

export interface CustomLoaderProps {
    position?: 'left' | 'center' | 'right';
    type?: LoaderType;
    classes?: { root?: string; loader?: string };
    visible?: boolean;
    darkColor?: boolean;
    loadingColor?: string;
    loadingSize?: number;
    styles?: {
        root?: React.CSSProperties;
        loader?: React.CSSProperties;
    };
}

export const CustomLoader = ({
    position = 'center',
    type = 'ball-pulse',
    visible = true,
    classes,
    loadingColor,
    loadingSize,
    darkColor,
    styles,
}: CustomLoaderProps) => {
    if (!visible) {
        return null;
    }

    const withPositionStyles = (): CSSProperties => {
        switch (position) {
            case 'left':
                return PositionLeftStyle;
            case 'center':
                return PositionCenterStyle;
            case 'right':
                return PositionRightStyle;
            default:
                return {};
        }
    };

    const style = { ...withPositionStyles(), ...styles?.root };

    const loaderStyle = makeLoaderStyle(Boolean(darkColor), loadingColor, loadingSize)();
    return (
        <LoaderContainer style={style} className={loaderStyle?.root}>
            <Loader active innerClassName={classNames(classes?.loader, loaderStyle?.loader)} type={type} />
        </LoaderContainer>
    );
};

const LoaderContainer = styled('div')({ display: 'flex' });

const PositionLeftStyle: CSSProperties = { justifyContent: 'flex-start' };
const PositionCenterStyle: CSSProperties = { justifyContent: 'center' };
const PositionRightStyle: CSSProperties = { justifyContent: 'flex-end' };

const BlueColor = '#13123A';

const makeLoaderStyle = (darkColor: boolean, loadingColor?: string, loadingSize?: number) => {
    const loadingColorStyle: CSSProperties = loadingColor ? { background: loadingColor } : {};
    const darkColorStyle: CSSProperties = darkColor ? { background: BlueColor } : {};
    const sizeStyle: CSSProperties = loadingSize ? { width: loadingSize, height: loadingSize } : {};

    return makeStyles({
        root: { display: 'flex', alignItems: 'center' },
        loader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'max-content',
            lineHeight: '0px',
            '&.loader-inner > div': {
                ...loadingColorStyle,
                ...darkColorStyle,
                ...sizeStyle,
            },
        },
    });
};
