export const floorPlanMessages = {
    Locale: 'fr-FR',

    COMMON_CM_UNIT: 'cm',

    AddRoomDialogTitle: 'Pièces',
    AddOpeningWarningDialogTitle: 'Ouvertures',
    AddRoomItemDialogTitle: 'Autres éléments',
    LaizeDirectionTitle_rouleau_vertical: 'Sens de pose : vertical',
    LaizeDirectionTitle_rouleau_horizontal: 'Sens de pose : horizontal',
    LaizeDirectionTitle_lamePleine_vertical: 'Disposition des lames : vertical',
    LaizeDirectionTitle_lamePleine_horizontal: 'Disposition des lames : horizontal',
    LaizeDirectionTitle_lamePleine_classique: 'Disposition des lames : optimisation des lames',
    LaizeDirectionTitle_lamePleine_non: 'Disposition des lames : optimisation des lames',
    LegendInfoText: 'Les cotes représentent les dimensions intérieures des pièces.',
    EditCoteTitle: 'Édition de cote',

    Opening_Door: 'Porte',
    Opening_ArmoredDoor: 'Porte blindée',
    Opening_FrenchDoor: 'Porte fenêtre',
    Opening_FrontDoor: "Porte d'entrée",
    Opening_PatioDoor: 'Baie vitrée',
    Opening_opening: 'Passage ouvert',

    RoomItem_ShowerBathtub: 'Douche / Baignoire',
    RoomItem_FixedFurniture: 'Meuble non déplaçable',
    RoomItem_Cupboard: 'Placard',
    RoomItem_KitchenFurniture: 'Meuble de cuisine',
    RoomItem_Trap: 'Trappe',
    RoomItem_Fireplace: 'Cheminée',
    RoomItem_Opening: 'Ouverture',
    RoomItem_Stairs: 'Marche',
    RoomItem_Divider: 'Cloison',
    RoomItem_HeavyElement: 'Élément lourd (jaccuzi, pot fleur...)',
    RoomItem_SewerPlate: 'Regard, plaque égout',

    RoomShape: 'Forme de pièce',

    PropertiesDialog_Title: 'Éditer les propriétés',
    PropertiesDialog_OpeningTypes: "Type d'ouverture",
    PropertiesDialog_RoomNamePropertyLabel: 'Nom de la pièce',
    PropertiesDialog_RoomNamePropertyLabelMissingError: 'Veuillez insérer un nom à la pièce.',
    PropertiesDialog_OpeningNamePropertyLabel: "Nom de l'ouverture",
    PropertiesDialog_OpeningNamePropertyLabelMissingError: "Veuillez insérer un nom à l'ouverture.",
    PropertiesDialog_OpeningSizePropertyLabel: 'Dimension*',
    PropertiesDialog_OpeningSizePropertyMissingError:
        'La dimension est trop petite ou dépasse la taille du mur le plus grand.',

    RoomDialog_SelectShapeLabel: 'Séléctionner la forme de votre pièce*',
    RoomDialog_SelectShapeInfo: 'Une fois sélectionné, vous ne pourrez plus revenir à cette étape.',
    RoomDialog_RoomTypePropertyLabel: 'Type de la pièce',
    RoomDialog_RoomTypePropertyMissingError: 'Veuillez sélectionner le type de pièce.',
    RoomDialog_RoomFloorPropertyLabel: 'Étage',
    RoomDialog_RoomFloorPropertyMissingError: "Veuillez sélectionner l'étage.",
    RoomDialog_RoomExistingFlooringPropertyLabel: "Présence d'un revêtement au sol",
    RoomDialog_RoomExistingFlooringPropertyMissingError: 'Veuillez indiquer si un revêtement de sol est déjà existant.',
    RoomDialog_RoomExistingFloorTypePropertyLabel: 'Sol actuel',
    RoomDialog_RoomExistingFloorTypePropertyMissingError: 'Veuillez sélectionner le type de sol actuel.',
    RoomDialog_RoomHeatingTypeFloorPropertyLabel: 'Type de chauffage au sol',
    RoomDialog_RoomHeatingTypeFloorPropertyMissingError: 'Veuillez sélectionner le type de chauffage au sol.',
    RoomDialog_RoomNamePropertyLabel: 'Nom de la pièce',
    RoomDialog_RoomNamePropertyDescription: 'Une fois sélectionné, vous ne pourrez plus revenir à cette étape.',

    OpeningWarningDialog_OpeningTypePropertyLabel: 'Séléctionner le type de votre ouverture*',
    OpeningWarningDialog_OpeningNamePropertyDescription: 'Une ouverture doit être comprise entre (mini) et (maxi) cm',
    OpeningWarningDialog_UnhingeableOpeningPropertyLabel: 'Porte dégondable',
    OpeningWarningDialog_UnhingeableOpeningPropertyMissingError: 'Veuillez indiquer si la porte est dégondable.',
    OpeningWarningDialog_DoorTypePropertyLabel: 'Type de porte',
    OpeningWarningDialog_DoorTypePropertyMissingError: 'Veuillez sélectionner le type de porte.',

    RoomItemDialog_Title: 'La pièce comporte...',
    RoomItemDialog_NamePropertyLabel: "Nom de l'élément",
    RoomItemDialog_NamePropertyLabelMissingError: 'Veuillez insérer un nom à cet élément.',
    RoomItemDialog_Stairs_Warning: 'Attention : Cet élément représente une seule marche.',
    RoomItemDialog_DimensionsLabel: 'Dimensions',
    RoomItemDialog_DimensionsWidthPropertyLabel: 'Largeur',
    RoomItemDialog_DimensionsWidthPropertyMissingError: "Veuillez saisir la largeur de l'élément.",
    RoomItemDialog_DimensionsWidthPropertyMissingErrorLimits:
        'Veuillez saisir la largeur comprise entre ({min}) et ({max}) cm',
    RoomItemDialog_DimensionsLengthPropertyLabel: 'Longueur',
    RoomItemDialog_DimensionsLengthPropertyMissingError: "Veuillez saisir la longueur de l'élément.",
    RoomItemDialog_DimensionsLengthPropertyMissingErrorLimits:
        'Veuillez saisir la longueur comprise entre ({min}) et ({max}) cm',
    RoomItemDialog_PutFlooringPropertyLabel: 'Faut-il y poser le revêtement ?',
    RoomItemDialog_PutPlinthesPropertyLabel: 'Faut-il poser des plinthes également ?',
    RoomItemDialog_IsMoveableOutsidePropertyLabel: "L'élement est situé en périphérie extérieure de la pièce ?",
    RoomItemDialog_IsMoveableOutsidePropertyDescription: "La surface de positionnement de l'élement est agrandie",
    RoomItemDialog_PutFlooringPropertyMissingError:
        "Veuillez indiquer s'il faut poser le revêtement à l'intérieur ou sur un partie de l'élément.",
    RoomItemDialog_PutPlinthesPropertyMissingError:
        "Veuillez indiquer s'il faut également poser des plinthes à l'intérieur de l'élément.",

    EditCoteDialog_DimensionPropertyLabel: 'Dimension (cm)',
    EditCoteDialog_DimensionPropertyDescription: 'la cote doit être comprise entre ({mini}) et ({maxi}) cm',

    Menu_Title: 'Clic & Pose',
    Menu_AddElement: 'Ajouter',

    Menu_OpeningArmoredDoorName: '{roomName} - Porte blindée',
    Menu_OpeningDoorName: '{roomName} - Porte',
    Menu_OpeningFrenchDoorName: '{roomName} - Porte fenêtre',
    Menu_OpeningFrontDoorName: "{roomName} - Porte d'entrée",
    Menu_OpeningOpeningName: '{roomName} - Passage ouvert',
    Menu_OpeningName: '{roomName} - {openingName}',

    Menu_LaizeDirectionInfo: 'Le choix du sens de pose a un impact sur la longueur nécessaire :',
    Menu_LaizeDirectionValueLabel: 'Nécessaire pour ce sens de pose',
    Menu_LaizeDirectionVertical: 'Vertical',
    Menu_LaizeDirectionHorizontal: 'Horizontal',
    Menu_LaizeValueUnit: '{size}m',

    CreateOpeningMode_InfoMessage: 'Faites une pression sur le plan pour placer votre ouverture',
    CreateOpeningMode_CancelLabel: 'Annuler la création',

    CreateRoomItemMode_InfoMessage: 'Faites une pression sur le plan pour placer votre élément',
    CreateRoomItemMode_CancelLabel: 'Annuler la création',

    RoomItemDialog_RoomTypeList_ChambreDressing: 'Chambre / Dressing',
    RoomItemDialog_RoomTypeList_Bureau: 'Bureau',
    RoomItemDialog_RoomTypeList_Salon: 'Salon',
    RoomItemDialog_RoomTypeList_SalleManger: 'Salle à manger',
    RoomItemDialog_RoomTypeList_Cuisine: 'Cuisine',
    RoomItemDialog_RoomTypeList_WC: 'WC',
    RoomItemDialog_RoomTypeList_SalleEau: "Salle d'eau ou salle de bain",
    RoomItemDialog_RoomTypeList_Veranda: 'Veranda',
    RoomItemDialog_RoomTypeList_HallEntree: 'Hall / entrée',
    RoomItemDialog_RoomTypeList_Couloir: 'Couloir',
    RoomItemDialog_RoomTypeList_Garage: 'Garage',
    RoomItemDialog_RoomTypeList_TerasseBalconCouvert: 'Terrasse ou balcon sous abri',
    RoomItemDialog_RoomTypeList_TerasseBalconNonCouvert: 'Terrasse ou balcon non couverte',
    RoomItemDialog_RoomTypeList_BordPiscinePlat: 'Bord de piscine plat',
    RoomItemDialog_RoomTypeList_BordPiscineIncline: 'Bord de piscine incliné',
    RoomItemDialog_RoomTypeList_Jardin: 'Jardin',
    RoomItemDialog_RoomTypeList_LocalProIndividuel: 'Local professionnel individuel',
    RoomItemDialog_RoomTypeList_PartieCommuneCoPro: "Parties communes d'une co-propriété",
    RoomItemDialog_RoomTypeList_LocalProCollectif: 'Local professionnel collectif',
    RoomItemDialog_RoomTypeList_ChambreHotel: "Chambre d'hotel",
    RoomItemDialog_RoomTypeList_Boutique: 'Boutique',
    RoomItemDialog_RoomTypeList_LieuTresFrequente: 'Lieu très fréquenté',
    RoomItemDialog_RoomTypeList_Industrie: 'Industrie',
    RoomItemDialog_RoomTypeList_Evenementiel: 'Évènementiel',
    RoomItemDialog_RoomTypeList_Ascenseur: 'Ascenseur',
    RoomItemDialog_RoomTypeList_AutresPartiesCommunes: 'Autres parties communes',
    RoomItemDialog_RoomTypeList_Cour: 'Cour',
    RoomItemDialog_RoomTypeList_ToitTerrasseAvecEtanchéite: 'Toit terrasse avec étanchéité',
    RoomItemDialog_RoomTypeList_Paves: 'Pavés',
    RoomItemDialog_RoomTypeList_GraviersEnrobes: 'Graviers / enrobés',
    RoomItemDialog_RoomTypeList_StructureBoisMetal: 'Structure (Bois, métal)',

    RoomItemDialog_RoomFloorList_RDC: 'RDC',
    RoomItemDialog_RoomFloorList_Etage1a3: '1er au 3ème étage',
    RoomItemDialog_RoomFloorList_4etPlus: '4ème étage et plus',
    RoomItemDialog_RoomFloorList_Floor: 'étage',

    RoomItemDialog_RoomCurrentFlooringList_Beton: 'Béton (Fondation dalle brute)',
    RoomItemDialog_RoomCurrentFlooringList_Ciment: 'Ciment (chape ou ragréage)',
    RoomItemDialog_RoomCurrentFlooringList_PlancherBois: 'Plancher en bois',
    RoomItemDialog_RoomCurrentFlooringList_BétonPeintCire: 'Béton peint ou ciré',
    RoomItemDialog_RoomCurrentFlooringList_ChapeSeche: 'Chape sèche (plaque de rénovation)',
    RoomItemDialog_RoomCurrentFlooringList_Terre: 'Terre',
    RoomItemDialog_RoomCurrentFlooringList_ParquetColle: 'Parquet collé',
    RoomItemDialog_RoomCurrentFlooringList_ParquetStratifieNonColle: 'Parquet ou stratifié non collé',
    RoomItemDialog_RoomCurrentFlooringList_Carrelage: 'Carrelage',
    RoomItemDialog_RoomCurrentFlooringList_Moquette: 'Moquette',
    RoomItemDialog_RoomCurrentFlooringList_RevetementPlastique: 'Plastique (vinyle, lino, dalle, gazon)',
    RoomItemDialog_RoomCurrentFlooringList_TerasseBoisComposite: 'Terrasse bois ou composite',

    RoomItemDialog_RoomHeatingTypeList_PasDeChauffage: "Je n'ai pas de chauffage au sol",
    RoomItemDialog_RoomHeatingTypeList_BasseTemperatureEau: 'Basse T° à eau (<28°)',
    RoomItemDialog_RoomHeatingTypeList_BasseTemperatureAccumulation: 'Basse T° à accumulation (<28°)',
    RoomItemDialog_RoomHeatingTypeList_HauteTemperature: 'Haute température (>28°)',
    RoomItemDialog_RoomHeatingTypeList_ElectriqueRayonnant: 'Électrique rayonnant',
    RoomItemDialog_RoomHeatingTypeList_Reversible: 'Réversible',
    RoomItemDialog_RoomHeatingTypeList_SystemeRapporte: 'Système rapporté',
    RoomItemDialog_RoomHeatingTypeList_UnknownType: 'Je ne connais pas le type de chauffage au sol',

    LaizeView_Title: 'CONSO PRODUIT',
    LaizeDirectionDialog_Description:
        "Le sens de pose ne se choisit pas au hasard, il impacte la quantité de produit : on peut opter pour une pose en fonction de l'entrée de la lumière dans la pièce, du sens de la marche ou encore en fonction du nombre de raccords. Si vous avez un projet multi-pièces le sens de pose sera identique. Réfléchissez-y, nous validerons avec vous !",
    LaizeDirectionView_length: 'Mètres linéaires',
    LaizeDirectionView_length_LamePleine_Horizontal: "Lames entières à l'horizontal",
    LaizeDirectionView_length_LamePleine_Vertical: 'Lames entières à la verticale',
    LaizeDirectionView_length_LamePleine_Classique: 'Optimisation des lames',

    AmenagementDialog_Title: "Etes-vous sûr de ne pas avoir d'aménagement ?",
    AmenagementDialog_Subtitle: `Une ouverture peut être une porte, porte-fenêtre, baie vitrée ou même un passage ouvert vers une autre pièce.
Un aménagement peut être un placard, une douche, des meubles de cuisine ... soit des d'éléments que nous déduisons de la surface totale de la pièce.
    
Positionnez-les pour avoir l'estimation la plus juste possible !`,
    AmenagementDialog_Yes: 'Voir mon devis',
    AmenagementDialog_No: 'Revenir au plan',

    OpeningWarningDialog_Title: 'Il manque quelque chose',
    OpeningWarningDialog_Subtitle: `Merci de compléter la section "Ma pièce" pour ajouter un aménagement sur cette pièce.`,
    OpeningWarningDialog_CTA: 'Compléter "Ma pièce"',

    Contournement_Toast_Success: 'Contournements enregistrés !',

    EstimationViewRollOver_Title_Complete: 'Voir votre devis',
    EstimationViewRollOver_Title_ToDo: 'Finaliser mon projet',
    EstimationViewRollOver_Title_NoRoom: 'Ajouter une pièce',
};

export type FloorPlanStringKey = keyof typeof floorPlanMessages;
