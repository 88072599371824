import { StoreProvider } from '../../../Configs/StoreProvider';
import { MeState } from '../../Me/MeReducer';
import { LogMessageUserFields } from '../LogMessageModel';


export class UserInformations {
    public static getUserInfo(): LogMessageUserFields {
        const Me: Partial<MeState> = StoreProvider.getState()?.Me || {};

        const loggedUser =
            Me.data?.stmac_store_properties ??
            Me.data?.bo_user_as_customer_properties ??
            Me.data?.bo_user_as_store_properties ??
            Me.data?.customer_properties;

        return { Id: loggedUser?.id, Type: UserInformations.toType(Me?.data?.type) };
    }

    public static toType = (type?: string): string | undefined => {
        switch (type) {
            case 'Customer':
                return 'Customer';
            case 'StmacStore':
                return 'StmacStore';
            case 'BoUserAsCustomer':
            case 'BoUserAsStore':
                return 'BackOfficeUser';
        }
        return undefined;
    }
}
