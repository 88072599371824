import { MeDto } from '../../Models/Users/Me';
import { MeAction, MeActions } from './MeActions';

export type MeState = { data?: MeDto; };

const initialMeState: MeState = { data: undefined };

export const MeReducer = (state = initialMeState, action: MeAction): MeState => {
    switch (action.type) {
        default:
            return state;
        case MeActions.SET_INFO:
            return {
                ...state,
                data: action.payload.info
            };
    }
};
