
export type EstimationUrlParameters = { version: string; ref?: string; }

export class EstimationUrlHelper {

    public static toQueryParam({ version, ref }: EstimationUrlParameters): URLSearchParams {
        const params = new URLSearchParams();

        const addQuery = (key: string, value: string = '') => {
            if (Boolean(value)) {
                params.append(key, value);
            } else {
                params.delete(key);
            }
        }
        addQuery('version', version);
        addQuery('ref', ref);

        return params;
    }
}