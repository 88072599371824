import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CRMQueryParameter } from '../../../Models/CRM/CRM';
import { AuthenticateResultType, AuthService } from '../../../Services/Authentication/AuthService';
import { useQuery } from '../../Hooks/URL/URLUtils';

import { LoadingView } from '../../Components/Widgets/LoadingView';
import { AppRoutes } from '../../../Configs/AppRoutes';

export const CRMLandingPage = () => {
    const { loginInProgress } = useCRMLandingPageController();
    return <LoadingView darkColor loading={loginInProgress} style={{ background: '#FFFFFF' }} />;
};

export const useCRMLandingPageController = () => {
    const browserHistory = useHistory();
    const params: CRMQueryParameter = useQuery<CRMQueryParameter>();

    const [loginInProgress, setLoginInProgress] = useState(false);

    React.useEffect(() => {
        crmLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const crmLogin = async () => {
        try {
            setLoginInProgress(true);
            const response: AuthenticateResultType = await AuthService.CRMlogin(params);
            setLoginInProgress(false);
            if (response === AuthenticateResultType.SUCCESS) {
                browserHistory.push({ pathname: AppRoutes.DEFAULT_ROUTE, state: { configId: params.id_config } });
            }
        } catch (error) {
            setLoginInProgress(false);
        }
    };

    return { loginInProgress };
};
