import { styled } from '@material-ui/styles';
import React from 'react';

import { BaseDialog } from '../../../../../Plugins/FloorPlan/Widgets/BaseDialog';
import { ButtonVariant, CustomButton } from '../../../../Components/Views/CustomButton/CustomButton';

export type SingleChoiceDialogProps = {
    title: string;
    body: React.ReactNode;
    labelChoice: React.ReactNode;
    onClose?: VoidFunction;
    onClickChoice: (setValidating: (validating: boolean) => void) => void;
};

export const SingleChoiceDialog = ({ title, labelChoice, body, onClose, onClickChoice }: SingleChoiceDialogProps) => {
    const [validating, setValidating] = React.useState(false);
    const onValidate = () => onClickChoice(setValidating);
    return (
        <BaseDialog title={title} subtitle={body} onClose={onClose}>
            <Content>
                <CustomButton
                    title={labelChoice}
                    styles={{ root: { flex: 1 } }}
                    variant={ButtonVariant.Blue}
                    onClick={onValidate}
                    isBusy={validating}
                />
            </Content>
        </BaseDialog>
    );
};

const Content = styled('div')({ marginTop: 37, display: 'flex', gap: 12 });
