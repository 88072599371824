import { Button, Popover } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Constants } from '../../../../../Constants';
import { StringKey } from '../../../../../Locales/fr';
import { I18n } from '../../../../../Locales/I18nService';
import { Assets } from '../../../../assets/Assets';
import { usePlatformContext } from '../../../../Context/PlatformContext/PlatformContext';
import { useAuthenticatedUser, UserLoggedState } from '../../../../Hooks/User/useAuthenticatedUser';
import { useIsVendeur } from '../../../../Hooks/Vendeur/useVendeur';
import { HeaderButton } from './HeaderButton';

export const ButtonUser = () => {
    const anchorEl = React.useRef(null);

    const [menuOpened, setMenuOpened] = React.useState(false);
    const openMenu = (): void => setMenuOpened(true);
    const closeMenu = (): void => setMenuOpened(false);

    const isVendeur = useIsVendeur();
    const { isAuthenticated, name, isStmacUser, isBoUser }: UserLoggedState = useAuthenticatedUser();

    const { isMobile } = usePlatformContext();

    return isAuthenticated && !isStmacUser ? (
        <Container ref={anchorEl}>
            <HeaderButton
                icon={<Assets.IconUser />}
                label={isMobile ? undefined : <FormattedMessage id="Header_MySpace" />}
                disabled={isVendeur}
                onClick={() => openMenu()}
            />
            <Popover
                open={menuOpened}
                anchorEl={anchorEl.current}
                anchorReference="anchorEl"
                onClose={closeMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ style: { display: 'flex', flexFlow: 'column' } }}>
                <Content>
                    {name && <UserName>{name}</UserName>}
                    {name && <LogoutButton closeMenu={closeMenu} />}
                    {(isBoUser || !Constants.isStMacProd()) && (
                        <VersionNumber>
                            <FormattedMessage
                                id="Header_Version"
                                values={{
                                    environment:
                                        I18n.get(`Header_Environment_${Constants.ENVIRONMENT}` as StringKey) ??
                                        I18n.get(`Header_Environment_localdev`),
                                    version_number: Constants.VERSION_NUMBER,
                                }}
                            />
                        </VersionNumber>
                    )}
                </Content>
            </Popover>
        </Container>
    ) : null;
};

export const HeaderButtonUser = React.memo(ButtonUser);

const Container = styled('div')({ height: '100%' });
const Content = styled('div')({ display: 'flex', flexDirection: 'column', alignItems: 'center' });
const UserName = styled('div')({ margin: 16, marginBottom: 0 });
const VersionNumber = styled('div')({ color: '#7f7f7f', fontSize: 9, textAlign: 'center', margin: 16 });

//#region  //* BTN LOGOUT
type LogoutButtonProps = { closeMenu: VoidFunction };
const LogoutButton = ({ closeMenu }: LogoutButtonProps) => {
    const { userLoaded, isAuthenticated, requestLogin, requestLogout }: UserLoggedState = useAuthenticatedUser();

    const internalOnLoginOrLogout = (): void => {
        if (isAuthenticated) {
            requestLogout(closeMenu);
        } else {
            requestLogin(closeMenu);
        }
    };

    return (
        <LogoutContainer onClick={internalOnLoginOrLogout}>
            <FormattedMessage id={userLoaded ? 'Header_Logout' : 'Header_Login'} />
        </LogoutContainer>
    );
};

const LogoutContainer = styled(Button)({
    margin: 16,
    textDecoration: 'underline',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'right',
    borderRadius: 0,
});

//#endregion
