import React from 'react';

import { CurrentProjectState } from '../Configs/CurrentProjectContext';
import { GestureState } from '../Plugins/FloorPlan/Context/GestureContext';
import { MapContextState } from '../Plugins/FloorPlan/Context/MapContext';
import { DebugContextState } from '../Web/Context/Debug/DebugContext';
import { QuoteState } from '../Web/Pages/Quote/QuoteState';
import { IncompatibilityState } from '../Plugins/FloorPlan/Context/IncompatibilityContext';

export const useWindowLog = (setState: Function) => {
    React.useEffect(() => {
        setState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

declare global {
    interface Window {
        MapConstants: any;
        CurrentProjectState: () => Partial<CurrentProjectState>;
        MapState: () => Partial<MapContextState>;
        QuoteState: () => Partial<QuoteState>;
        IncompatibilityState: () => Partial<IncompatibilityState>;
        GestureState: () => Partial<GestureState>;
        DebugState: () => Partial<DebugContextState>;
    }
}
