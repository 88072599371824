export const GTMTexts = {
    //* Evaluations names
    habitation_amenagement_type: 'Localisation',    //! OBSOLETE IN MULTI PRODUIT
    habitation_amenagement_lieu: 'Type de local',   //! OBSOLETE IN MULTI PRODUIT
    habitation_user_type: 'Statut habitation',
    habitation_type: 'Date habitation',

    //* Page paths and titles
    OnboardingStart_Path: '/simulateur-pose/00/onboarding-demarrage',       //* Ouverture d'un projet vide ou démarage
    OnboardingStart_Title: 'simulateur-pose/00/onboarding-demarrage',
    OnboardingEnd_Path: '/simulateur-pose/00/onboarding-finalisation',      //* Clique sur suivant à la fin du onboarding
    OnboardingEnd_Title: 'simulateur-pose/00/onboarding-finalisation',
    MapEmpty_Path: '/simulateur-pose/00/plan-en-attente',                   //* Aucunes pièce/aménagements sur le canvas (Fin onboarding + suppression pièce)
    Plan_Path: '/simulateur-pose/01/mon-plan',                              //* Ma Piece + Mes amenagements
    Plan_Title: 'simulateur-pose-01-mon-plan',
    Pose_Path: '/simulateur-pose/02/pose',                                  //* Ma pose
    Pose_Title: 'simulateur-pose-02-pose',
    Support_Path: '/simulateur-pose/03/mon-support',                        //* Mon support
    Support_Title: 'simulateur-pose-03-mon-support',
    Preparation_Path: '/simulateur-pose/04/preparation',                    //* Ma préparation
    Preparation_Title: 'simulateur-pose-04-preparation',
    Finitions_Path: '/simulateur-pose/05/finitions',                        //* Mes finitions
    Finitions_Title: 'simulateur-pose-05-finitions',
    Services_Path: '/simulateur-pose/06/services',                          //* Mes services
    Services_Title: 'simulateur-pose-06-services',

    // Produit_Path: '/simulateur-pose/01/mon-produit-a-poser',                //* Quand on arrive à la question produit
    // Produit_Title: 'simulateur-pose-01-mon-produit-a-poser',
    // Habitation_Path: '/simulateur-pose/02/mon-habitation',                  //* Onboarding (Question habitations)
    // Habitation_Title: 'simulateur-pose-02-mon-habitation',
    // Estimation_Path: '/simulateur-pose/09/estimation',                      //* Clic sur le header "Devis estimatif" (avec ou sans calcul de prix)
    // Estimation_Title: 'simulateur-pose-09-estimation',
    // Estimation_Connecte_Path: '/simulateur-pose/09a/estimation-connecte',   //* Clic sur le header "Devis estimatif" en étant connecté (avec ou sans calcul de prix)
    // Estimation_Connecte_Title: 'simulateur-pose-09a-estimation-connecte',
};
