import { RouterState } from 'connected-react-router';
import { useSelector } from 'react-redux';
import * as Redux from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as ReduxLoger from 'redux-logger';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ThunkMiddleware from 'redux-thunk';

import { Constants } from '../Constants';
import { AuthenticationMiddleware } from '../Services/Authentication/AuthenticationMiddleware';
import { MeMiddleware } from '../Services/Me/MeMiddleware';
import { AuthenticationReducer, AuthenticationState } from '../Services/Authentication/AuthenticationReducer';
import { MeReducer, MeState } from '../Services/Me/MeReducer';
import { frMessages } from '../Locales/fr';
import { RouterReducer } from './browserHistory';
import { StringsState, I18nReducer } from '../Locales/i18nReducer';

const I18n: StringsState = {
    locale: 'fr',
    defaultLocale: 'fr',
    supported_locales: ['fr'],
    messages: frMessages,
};

export type AppState = {
    router: RouterState;
    I18n: StringsState;
    Authentication: AuthenticationState;
    Me: MeState;
};

export let store: Redux.Store<AppState>;

export type PolStoreConfig = { store: Redux.Store<AppState>; persistor: Persistor };

export class PolStore {
    public static getStore = (): PolStoreConfig => {
        const middlewares: Array<Redux.Middleware> = [ThunkMiddleware, AuthenticationMiddleware, MeMiddleware];
        if (Constants.isLocalDev) {
            middlewares.push(
                ReduxLoger.createLogger({
                    //* action from RouterReducer
                    predicate: (getState, action) => !action.type.includes('@@router'),
                })
            );
        }

        const rawAppReducer = Redux.combineReducers({
            router: RouterReducer,
            I18n: I18nReducer,
            Authentication: AuthenticationReducer,
            Me: MeReducer,
        });

        const ApplicationReducer = persistReducer(
            {
                key: Constants.AUTH_STORAGE_KEY,
                whitelist: ['Authentication'],
                storage,
            },
            rawAppReducer
        );

        const composeEnhancers = composeWithDevTools({});
        const enhancer = composeEnhancers(Redux.applyMiddleware(...middlewares));

        const initialAppState = { I18n };
        store = Redux.createStore(ApplicationReducer, initialAppState, enhancer);
        const persistor: Persistor = persistStore(store);
        return { store, persistor };
    };
}

export const useAppState = (): AppState => useSelector((state: AppState) => state);

export const useMe = () => useSelector((state: AppState) => state.Me);
