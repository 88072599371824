import { ObjectUtils } from '../../Helpers/ObjectUtils';
import { MapContextState } from '../../Plugins/FloorPlan/Context/MapContext';
import { AuthService } from '../../Services/Authentication/AuthService';
import { CreatedByTypeDto, ProjectDto } from '../Projects/ProjectDto';
import { QuotationReplyBaseDto } from '../Quotations/Quotations';
import { StoreDto } from '../TravelExpenses/StoreDto';
import { StmacPostMessage } from './StmacPostMessage';

export enum SaveProjectType {
    Request = 'save_project:pol',
    Response = 'res:save_project:pol',
}

export enum ProjectOrigin {
    Vendeur = 1,
    Client = 2,
}

export interface SaveProjectRequestMessage extends StmacPostMessage {
    token?: string; // token : jwt customer connected

    /** Optional. If provided, the existing project will be updated instead of creating a new one.**/
    project_id?: string;

    /** Numeric prices (as stored in DB).*/
    product_price?: number;

    accessories_price?: number; //* total_other_produits

    total_price?: number;
    service_price?: number;

    /* Shipping information */
    shipping_price?: number;
    shipping_store_quotation_needed?: boolean;

    /** Formatted prices string (as displayed to the user).*/
    product_price_formatted?: string;

    accessories_price_formatted?: string; //* total_other_produits
    service_price_formatted?: string; //* unknown from ideine
    total_price_formatted?: string;

    /** Surface in m².*/
    surface_m2?: number;

    /** Formatted surface string.*/
    surface?: string;

    project_name?: string;
    product_name?: string;
    product_qct?: string;

    /** Magasin ID. Default to 050 (web store). */
    store_id?: string;
    config_id?: string;

    /** 1: vendeur | 2: client */
    project_origin?: ProjectOrigin;
}

export interface SaveProjectResponseMessage extends StmacPostMessage {
    readonly success: boolean;
    readonly client_id: string;
    readonly project_id: string;
    //* true if project was updated, false if it was created
    readonly project_updated: boolean;
}

interface GetSaveProjectRequestMessageParameters {
    projet: ProjectDto;
    projetStore?: StoreDto;
    quotationReply: QuotationReplyBaseDto | undefined;
    mapState: MapContextState;
}

export class StmacSaveProjectMessage {
    public static SAVE_PROJECT_REQUEST_ID = 'POL_SAVE_PROJECT';
    public static SAVE_PROJECT_REQUEST_ID_SEPARATOR = ':'; //* add project id

    public static isPostMessageResSaveProject = (type: string) => type === SaveProjectType.Response;

    public static formatRequestId = (projectId: string) => {
        return `${StmacSaveProjectMessage.SAVE_PROJECT_REQUEST_ID}${StmacSaveProjectMessage.SAVE_PROJECT_REQUEST_ID_SEPARATOR}${projectId}`;
    };

    public static getOriginProjectIdFromRequestId = (tid: string): string | undefined => {
        const requestIds = tid.split(StmacSaveProjectMessage.SAVE_PROJECT_REQUEST_ID_SEPARATOR);
        if (requestIds.length === 2) {
            return requestIds[1];
        }
        return undefined;
    };

    public static toProjectOrigin(createdByType: CreatedByTypeDto): ProjectOrigin | undefined {
        switch (createdByType) {
            case CreatedByTypeDto.Customer:
                return ProjectOrigin.Client;
            case CreatedByTypeDto.Store:
                return ProjectOrigin.Vendeur;
            default:
                return undefined;
        }
    }

    public static isStoreIdValid = (storeId?: string) => {
        return Boolean(storeId?.replaceAll('0', '').length);
    };

    public static toDataToLog = (requestMessage: SaveProjectRequestMessage): SaveProjectRequestMessage => {
        const dataToLog: SaveProjectRequestMessage = ObjectUtils.clone(requestMessage);

        const token = requestMessage.token || '';
        const first3letters = token.substring(0, 3);
        const last3letters = token.substring(token.length - 3, token.length);
        dataToLog.token = `${first3letters}xxxx${last3letters}`;
        return dataToLog;
    };

    public static getSaveProjectRequestMessage = async ({
        projet,
        projetStore,
        quotationReply,
        mapState,
    }: GetSaveProjectRequestMessageParameters): Promise<SaveProjectRequestMessage> => {
        //todo : fix @mmc
        const mainProduit = mapState.rooms?.[0].questionProduitPrincipal?.produitValue;
        const storeId = projetStore?.store_id!;

        const saveMessage: SaveProjectRequestMessage = {
            type: SaveProjectType.Request,
            tid: StmacSaveProjectMessage.formatRequestId(projet.id),

            project_id: projet.projet_reference_id,
            config_id: projet.configuration_id,
            store_id: storeId,
            project_name: projet.intitule,
            project_origin: StmacSaveProjectMessage.toProjectOrigin(projet.created_by_type),

            token: quotationReply?.quotation?.token ?? (await AuthService.getAccessToken()),
            surface_m2: mapState.global_surface_area,

            //todo: @mmc fix
            product_name: mainProduit?.libelle,
            product_qct: mainProduit?.code,

            product_price: quotationReply?.detail_main_produit_list?.reduce((acc, cur) => acc + cur?.total_ttc, 0),
            total_price: quotationReply?.detail_total_price?.total_price_ttc,
            accessories_price: quotationReply?.detail_produits_complementaires?.total_produits_complementaires_ttc,
            service_price: quotationReply?.detail_main_oeuvre_pose?.total_pose,
        };
        const livraison = quotationReply?.detail_livraison;
        if (livraison) {
            saveMessage.shipping_store_quotation_needed = livraison.is_store_quotation_needed;
            if (!livraison.is_store_quotation_needed) {
                saveMessage.shipping_price = livraison.total_livraison_ttc;
            }
        }

        return saveMessage;
    };

    public static isValid = (saveMessage: SaveProjectRequestMessage) => {
        //!\\ 35227 C&P - Sécurisation Post_Message (Error : 400 Bad Request) when "Missing field: product_qct"
        const hasProductQct = Boolean(saveMessage.product_qct);
        //!\\ + Afin d' éviter des erreurs techniques ,  il ne faut pas appeler le postMessage si le qct et la surface ne sont pas renseignées.
        const hasSurface = Boolean(saveMessage.surface_m2);

        const hasStoreId = this.isStoreIdValid(saveMessage.store_id);
        const hasTotalPrice = Boolean(saveMessage.total_price);

        return (hasSurface && hasProductQct) && (hasStoreId || hasTotalPrice);
    }
}
