import { MeDto } from '../../Models/Users/Me';

export enum MeInitiliazedReason {
    SET_INFO = 'app/me/set/info',
}

export interface SetInfoAction {
    type: typeof MeActions.SET_INFO;
    payload: { info?: MeDto; reason?: MeInitiliazedReason | string };
}

export type MeAction = SetInfoAction;

export class MeActions {
    public static SET_INFO = 'app/me/set/info';

    public static setInfo = (info?: MeDto): SetInfoAction => ({
        type: MeActions.SET_INFO,
        payload: { info },
    });
}
