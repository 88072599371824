import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

export class DateUtils {
    static formatDateCustom = <DefaultValue extends any>(
        rawDate: string | Date,
        customFormat: string,
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue => {
        const parsedDate = DateUtils.customParseDate<DefaultValue | string>(rawDate, defaultValue, {
            acceptFalsyDefault: true,
        });

        if (parsedDate === defaultValue) return defaultValue;

        return parsedDate
            ? format(parsedDate as Date, customFormat, {
                  useAdditionalWeekYearTokens: true,
                  locale: fr,
              })
            : defaultValue;
    };

    /**
     * @param {String|Date} rawDate
     * @param {*} defaultValue
     * @returns {String}
     */
    static formatDateTime = <DefaultValue extends any>(
        rawDate: string | Date = '',
        defaultValue: DefaultValue | string = '-'
    ): string | DefaultValue => DateUtils.formatDateCustom<DefaultValue>(rawDate, `dd/MM/yyyy - HH'h'mm`, defaultValue);

    //#region //* NESTED

    /**
     * @param {String|Date} date - ISO format
     * @param {*} defaultValue
     * @param {Object|String} options
     * @param {Boolean} options.acceptFalsyDefault - return the 'defaultDate' even if it's falsy
     * @returns {Date} - the parsed Date, or the default date, or the current date in that order
     */
    static customParseDate = <DefaultValue = any>(
        date: string | Date,
        defaultDate: DefaultValue,
        { acceptFalsyDefault }: { acceptFalsyDefault?: boolean } = {}
    ): Date | DefaultValue => {
        if (typeof date === 'string' && isValid(parseISO(date))) return parseISO(date);

        if (date instanceof Date && isValid(date)) return date;

        if (acceptFalsyDefault) return defaultDate;

        return defaultDate || new Date();
    };

    //#endregion
}
