import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LoadingPage } from './Loading/LoadingPage';

export type ReduxAppProps = { store: any; persistor: any };
export const ReduxApp: React.FC<ReduxAppProps> = ({ store, persistor, children }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingPage />} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};
