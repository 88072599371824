import React from 'react';
import { useSelector } from 'react-redux';

import { useStmacLoginViewGateController } from './StmacLoginViewGateController';
import { AppState } from '../../../Configs/PolStore';
import { Constants } from '../../../Constants';
import { TokenType } from '../../../Models/Authentication/AuthResponse';
import { LoadingGate } from '../../Components/Templates/Wrappers/LoadingGate/LoadingGate';

//* True when the app is opened from StMaclou's CRM
type StmacLoginViewGateProps = { isAuthenticatedFromUrl?: boolean };

export const StmacLoginViewGate: React.FC<StmacLoginViewGateProps> = ({ isAuthenticatedFromUrl, children }) => {
    const { isAuthenticated, isCustomer } = useSelector((state: AppState) => {
        return {
            isAuthenticated: state.Authentication.isAuthenticated,
            isCustomer: state.Authentication.token_type === TokenType.StmacJWT,
        };
    });

    const login = useStmacLoginViewGateController({ isAuthenticated, isAuthenticatedFromUrl });
    const mountIframe = (!isAuthenticated && !isAuthenticatedFromUrl) || isCustomer;

    React.useEffect(() => {
        if (mountIframe) {
            login.subscribeOnMessage();
        }
        return () => login.unSubscribeOnMessage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mountIframe]);

    return (
        <>
            {mountIframe && (
                <iframe
                    id={Constants.STMAC_FRAME_ID}
                    title="StmacLogin-po"
                    src={Constants.STMAC_LOGIN_URL}
                    hidden={true}
                />
            )}
            {login.authReady || login.isStMacLoginTimout || Boolean(isAuthenticatedFromUrl) ? (
                children
            ) : (
                <LoadingGate showLoader />
            )}
        </>
    );
};
