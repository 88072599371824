import { useLocation } from 'react-router-dom';

export const useQuery = <T extends { [key: string]: any }>(): T => {
    return useQueryCore(useLocation());
};

export const useQueryCore = <T extends { [key: string]: any }>(location: { search: string }): T => {
    const query = new URLSearchParams(location.search);
    const results: { [key: string]: any } = {};
    query.forEach((value: string, key: string) => {
        results[key] = value;
    });
    return results as T;
};
