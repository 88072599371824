import { Route } from "react-router-dom";
import { RouteItem } from "../Web/Components/Templates/AppRouter/RouteItem";
import { CRMLandingPage } from "../Web/Pages/CRMLanding/CRMLandingPage";
import { QuotePageLoading } from "../Web/Pages/Quote/QuotePageLoading";
import { ProjectsPageLoading } from "../Web/Pages/Projects/ProjectsPageLoading";
import { ProjectDetailsPage } from "../Web/Pages/Projects/ProjectDetailsPage/ProjectDetailsPage";

export class AppRoutes {

  public static CRM_LANDING = "/crm/devis";   //* CRM
  public static ESTIMATION = "/estimation";   //* ESTIMATION

  //* MY PROJECTS
  public static MY_PROJECTS = "/projets";
  public static PROJECT_DETAILS = "/projets/:projectId";
  public static GET_PROJECT_DETAILS = (id: string) => `/projets/${id}`;

  public static DEFAULT_ROUTE = AppRoutes.ESTIMATION;

  public static getRoutes = (): RouteItem[] => [
    { render: Route, props: { path: AppRoutes.CRM_LANDING, component: CRMLandingPage } },
    { render: Route, props: { path: AppRoutes.ESTIMATION, component: QuotePageLoading } },
    //* PROJECTS
    { render: Route, props: { path: AppRoutes.MY_PROJECTS, component: ProjectsPageLoading } },
    { render: Route, props: { path: AppRoutes.PROJECT_DETAILS, component: ProjectDetailsPage } }
  ];
}

