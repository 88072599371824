import { Theme, useMediaQuery } from '@material-ui/core';
import Bowser from 'bowser';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18n } from '../../../Locales/I18nService';

//* Can be usefull for platform context like #ANDROID #IPHONE #IPAD

// Breakpoints
// xs, extra-small: 0px.
// sm, small: 600px.
// md, medium: 960px.
// lg, large: 1280px.
// xl, extra-large: 1920px.

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

export const BreakpointSize = {
    XS: 0, //* mobile
    SM: 600, //* tablete like nexus 7
    MD: 960, //* ipad
    LG: 1280,
    XL: 1920,
} as const;

export interface IPlatformContextValue {
    isMobile: boolean;
    isTablet: boolean;
    browser?: Bowser.Parser.Details;
    isValidBrowser: boolean;
}

const DefaultPlatformContextValue: IPlatformContextValue = {
    isMobile: false,
    isTablet: false,
    browser: undefined,
    isValidBrowser: false,
};

const PlatformContext = React.createContext<IPlatformContextValue>(DefaultPlatformContextValue);
PlatformContext.displayName = 'PlatformContext';
export const usePlatformContext = (): IPlatformContextValue => React.useContext(PlatformContext);
export const useIsMobile = () => usePlatformContext().isMobile;

export const PlatformContextProvider: React.FunctionComponent = ({ children }) => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
        mobile: {
            safari: '>11.2',
            'android browser': '>=94',
            opera: '>=64',
            'Samsung Internet for Android': '>8.2',
        },

        desktop: {
            safari: '>11',
            opera: '>46',
        },

        firefox: '>54',
        chrome: '>59',
        edge: '>=80',
        'internet explorer': '>11',
    });

    const MOBILE_LIMIT_WIDTH = 900;

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(MOBILE_LIMIT_WIDTH), { noSsr: true });

    const platformContextValue: IPlatformContextValue = {
        isMobile,
        isTablet: false,
        browser: browser.getBrowser(),
        isValidBrowser: isValidBrowser ?? false,
    };

    return (
        <HelmetProvider>
            <PlatformContext.Provider value={platformContextValue}>
                <Helmet>
                    <title>{I18n.get('ApplicationName')}</title>
                </Helmet>
                {children}
            </PlatformContext.Provider>
        </HelmetProvider>
    );
};
