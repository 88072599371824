import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteItem } from './RouteItem';

export type AppRouterProps = { browserHistory: History; defaultRoot: string; routes: Array<RouteItem> };

export const AppRouter = ({ browserHistory, defaultRoot, routes }: AppRouterProps) => {
    return (
        <ConnectedRouter history={browserHistory}>
            <Switch>
                {routes.map(({ render: Render, props }) => {
                    const { exact = true } = props;
                    return <Render key={`${props.path}_${exact ? 'exact' : ''}`} {...props} exact={exact} />;
                })}
                <Redirect to={defaultRoot} />
            </Switch>
        </ConnectedRouter>
    );
};
