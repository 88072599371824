import './BaseDialog.scss';

import { DialogProps, DialogTitle, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import classNames from 'classnames';
import React from 'react';

import { Assets } from '../../../../assets/Assets';

export type BaseDialogProps = DialogProps & {
    title?: string;
    onClose?(): void;
    hideCloseButton?: boolean;
    renderTitle?: () => React.ReactNode;
    children: React.ReactNode;
    rounded?: boolean;
    baseDialogClasses?: { header?: string; body?: string };
};

export const BaseDialog = ({
    title,
    onClose,
    children,
    hideCloseButton = false,
    renderTitle,
    rounded = false,
    baseDialogClasses,
    ...rest
}: BaseDialogProps) => {
    //#region //* CLASSESS

    const enhanceBaseDialogPaperClasses = classNames('BaseDialog-paper', { rounded });

    // const useOverrideHeaderClass = baseDialogClasses?.header;
    // const enhancedBaseDialogHeaderClasses = classNames({
    //     'BaseDialog-header': !useOverrideHeaderClass,
    //     [baseDialogClasses?.header ?? '']: useOverrideHeaderClass,
    // });

    // const useOverrideBodyClass = baseDialogClasses?.body;
    // const dialogBodyClasses = classNames({
    //     'BaseDialog-body': !useOverrideBodyClass,
    //     [baseDialogClasses?.body ?? '']: useOverrideBodyClass,
    // });

    const enhancedBaseDialogHeaderClasses = classNames('BaseDialog-header', baseDialogClasses?.header);
    const dialogBodyClasses = classNames('BaseDialog-body', baseDialogClasses?.body);

    //#endregion

    const RenderDialogTitle = (): React.ReactNode => (
        <React.Fragment>
            {title && <DialogTitle className="BaseDialog-header-title">{title}</DialogTitle>}
        </React.Fragment>
    );

    return (
        <Dialog
            onClose={onClose}
            {...rest}
            classes={{
                root: 'BaseDialog-root',
                container: 'BaseDialog-container',
                paper: enhanceBaseDialogPaperClasses,
            }}>
            <div className="BaseDialog-content">
                <div className={enhancedBaseDialogHeaderClasses}>
                    {renderTitle ? renderTitle() : RenderDialogTitle()}
                    {onClose && (
                        <IconButton classes={{ root: 'BaseDialog-header-btn-close' }} onClick={onClose}>
                            <Assets.IconCloseWhite />
                        </IconButton>
                    )}
                </div>
                <div className={dialogBodyClasses}>{children}</div>
            </div>
        </Dialog>
    );
};
