import { ApiDataResponse } from '../ApiData';
import { SetConfigurationDto } from './Configuration';

export enum CreatedByTypeDto {
    Customer = 'Customer',
    Store = 'Store',
}

export interface CustomerDto {
    readonly id: string;
    readonly first_name: string;
    readonly last_name: string;
}

export type CreateByStoreDto = { id: string };

export interface CustomerActionSummaryDto {
    readonly date: string;
    readonly user: CustomerDto;
}

export type StoreActionSummaryDto = { date: string; store: CreateByStoreDto };

export interface QuotationSummaryDto {
    readonly id: string;
    readonly created_date: string;
    readonly room_count: number;
    readonly total_amount: number;
    readonly configuration_id: string;
}

export interface ProjectDto {
    readonly id: string;
    readonly projet_reference_id: string;
    readonly intitule: string;
    readonly description: string;
    readonly total_surface: number;
    readonly opening_count: number;
    readonly room_count: number;
    readonly configuration_id: string;
    readonly created_by_type: CreatedByTypeDto;
    readonly created_by_customer?: CustomerActionSummaryDto;
    readonly created_by_store?: StoreActionSummaryDto;
    readonly customer_id: string;
    readonly last_saved_date: string; //! ISO date
    readonly quotation_list: Array<QuotationSummaryDto>;
}

export interface ProjectSummaryDto {
    readonly id: string;
    readonly intitule: string;
    readonly created_by_type: CreatedByTypeDto;
    readonly created_by_customer?: CustomerActionSummaryDto;
    readonly created_by_store?: StoreActionSummaryDto;
    readonly last_saved_date: string;
}

export interface PostProjetDto {
    readonly intitule: string;
    readonly description?: string;
    readonly configuration?: SetConfigurationDto;
    readonly from_projet_id?: string;
}

export interface UpdateProjetDto {
    readonly intitule: string;
    readonly description?: string;
    readonly customer_id?: string;
}

export interface SetProjetReferenceDto {
    readonly projet_reference_id: string;
}

export interface SetProjectReplyDto {
    readonly id: string;
    readonly projet: ProjectDto;
}

export interface ImportProjectsResultDto {
    readonly imported_count: number;
    readonly ignore_count: number;
    readonly project_with_customer_id: string;
}

export type SetProjectReplyResponse = ApiDataResponse<SetProjectReplyDto>;

export type ProjectResponse = ApiDataResponse<ProjectDto>;

export type ProjectsResponse = ApiDataResponse<Array<ProjectSummaryDto>>;

export type ImportProjectsResultResponse = ApiDataResponse<ImportProjectsResultDto>;
