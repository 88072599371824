import { AnyAction, Middleware, MiddlewareAPI } from 'redux';

import { NavigationService } from '../../Web/Services/NavigationService';

import { TokenType } from '../../Models/Authentication/AuthResponse';
import { MeService } from '../Me/MeService';
import { AuthenticationState } from './AuthenticationReducer';
import * as AuthenticationTypes from './typings/AuthenticationActionTypes';
import { ReloadAppActions } from '../../Web/Components/Templates/Wrappers/MountActionsGate/EssentialsActionsGate';

export const AuthenticationMiddleware: Middleware =
    ({ dispatch, getState }: MiddlewareAPI) =>
        (next: any) =>
            (action: AnyAction) => {
                if (action) {
                    switch (action.type) {
                        case AuthenticationTypes.AuthActionType.LOG_OUT:
                            NavigationService.goToHome();
                            break;
                        case ReloadAppActions.APP_ESSENTIAL_RELOAD:
                            const res = next(action);
                            const { token_type, access_token = '' } = (getState().Authentication || {}) as AuthenticationState;
                            if (token_type === TokenType.Bearer && access_token) {
                                MeService.getMyInfo().catch((err: any) => err);
                            }
                            return res;
                        default:
                            break;
                    }
                }
                return next(action);
            };
