/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState, useMe } from '../../../Configs/PolStore';

import { GTM } from '../../../Plugins/GTM/GTM';
import { TokenType } from '../../../Models/Authentication/AuthResponse';
import { AuthenticationState } from '../../../Services/Authentication/AuthenticationReducer';
import { AuthService } from '../../../Services/Authentication/AuthService';
import { MeState } from '../../../Services/Me/MeReducer';

import { UserTypeDto } from '../../../Models/Users/Me';
import { UserUtils } from '../../../Utils/UserUtils';
import { DialogService, ValidateActionParams } from '../../Services/DialogService';

export interface UserLoggedState {
    userLoaded: boolean;
    name: string;
    requestLogin: (onLoginFinished: () => void) => void;
    requestLogout: (onLogoutFinished: () => void) => void;
    isStmacUser: boolean;
    isAuthenticated: boolean;
    isBoUser: boolean;
}

export const useAuthenticatedUser = (): UserLoggedState => {
    const me: MeState = useMe();
    const authentication: AuthenticationState = useSelector((state: AppState) => state.Authentication);

    const i18n = useIntl();

    //#region //* LOGIN
    const requestLogin = (afterLoginSuccess: () => void): void => {
        DialogService.showStmacLoginDialog({ afterLoginSuccess });
    };
    //#endregion

    //#region //* REGION LOGOUT
    const logout = async (): Promise<void> => AuthService.logout('user click on logout');

    const requestLogout = (onLogoutFinished: () => void): void => {
        const messages = i18n.messages as Record<string, any>;
        DialogService.showYesNoChoiceDialog({
            title: messages['Dialog_LogoutTitle'],
            subtitle: messages['Dialog_LogoutMessageHeader'],
            body: messages['Dialog_LogoutMessageBody'],
            onClickYes: async ({ closeDialog, setValidating }: ValidateActionParams) => {
                setValidating(true);
                await logout();
                setValidating(false);
                closeDialog(onLogoutFinished);
            },
            onClickNo: onLogoutFinished,
            onClosed: onLogoutFinished,
        });
    };

    //#endregion

    //* Push user origin to Google Tag Manager
    useEffect(() => {
        GTM.pushUserOrigin(me?.data?.type);
        GTM.pushCurrentClientId(me?.data?.customer_properties?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me?.data?.type]);

    const state = useMemo(() => {
        return {
            userLoaded: Boolean(me?.data),
            name: UserUtils.getFullName(me?.data),
            isStmacUser: authentication.token_type === TokenType.StmacJWT,
            isAuthenticated: authentication.isAuthenticated,
            requestLogin,
            requestLogout,
            isBoUser:
                Boolean(me.data?.type) &&
                [UserTypeDto.BoUserAsCustomer, UserTypeDto.BoUserAsStore].includes(me.data!.type),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    return state;
};
