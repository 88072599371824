import { useState } from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { styled } from '@material-ui/styles';

type CopyToClipboardButtonProps = {
    data: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
    const [open, setOpen] = useState(false);

    const onClick = () => {
        navigator.clipboard.writeText(props.data);
        setOpen(true);
    };

    return (
        <Container {...props}>
            <IconButton onClick={onClick}>
                <FileCopyIcon />
            </IconButton>
            <Snackbar
                message={<FormattedMessage id="CopyToClipboardButton_CopiedSnakbarMessage" />}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
            />
        </Container>
    );
};

const Container = styled('div')({
    width: 24,
    height: 24,
});
