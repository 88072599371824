import * as AuthenticationTypes from './typings/AuthenticationActionTypes';
export interface AuthenticationState {
    isAuthenticated: boolean;
    initialized: boolean;
    error: any;

    //* EMAIL
    access_token: string;
    refresh_token: string;
    token_type: string;
}

const initialAuthState: AuthenticationState = {
    isAuthenticated: false,
    initialized: false,
    error: null,

    // EMAIL
    access_token: '',
    refresh_token: '',
    token_type: '',
};

export const AuthenticationReducer = (
    state = initialAuthState,
    action: AuthenticationTypes.AuthenticationAction
): AuthenticationState => {
    switch (action.type) {
        default:
            return state;
        case AuthenticationTypes.AuthActionType.LOG_OUT:
            return { ...initialAuthState, initialized: true };
        case AuthenticationTypes.AuthActionType.SET_AUTHENTICATED:
            return {
                ...state,
                access_token: action.payload.authorization.access_token,
                refresh_token: action.payload.authorization.refresh_token,
                token_type: action.payload.authorization.token_type,
                isAuthenticated: action.payload.isAuthenticated,
                initialized: action.payload.initialized,
            };
    }
};

