export enum ApiErrorCodes {
    INVALID_CLIENT_MESSAGE = 'INVALID_CLIENT_MESSAGE',
    INVALID_CREDENTIALS_MESSAGE = 'Invalid user login and/or password',

    UPDATE_NOT_ALLOWED = 'Authenticated user is not allowed to UPDATE this projet',

    TOKEN_IN_FUTURE = 'token in futur',

    //* QUOTATIONS
    PRIX_NOT_FOUND = 'PRIX_NOT_FOUND',
    POSE_NOT_FOUND = 'POSE_NOT_FOUND',
    REFERENCE_QUESTION_NOT_FOUND = 'REFERENCE_QUESTION_NOT_FOUND',
    PRODUIT_NOT_FOUND = 'PRODUIT_NOT_FOUND',
    CODE_TVA_NOT_FOUND = 'CODE_TVA_NOT_FOUND',
    PRICE_CALCULATION_ERROR = 'PRICE_CALCULATION_ERROR',
}
