import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Constants } from '../../Constants';
import { GTMTexts } from '../GTM/GTMTexts';

export class Didomi {
    public static initialize = (): void => {
        //* Initialize GTM
        const tagManagerArgs: TagManagerArgs = { gtmId: Constants.GTM_ID };
        TagManager.initialize(tagManagerArgs);

        //* Refresh GTM when consent changes (opt-in or opt-out)
        window.didomiEventListeners = window.didomiEventListeners || [];
        window.didomiEventListeners.push({
            event: 'consent.changed',
            listener: function (context) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'pageview_virtual',
                        pageview_virtual_path: GTMTexts.OnboardingStart_Path,
                        pageview_virtual_title: GTMTexts.OnboardingStart_Title,
                    },
                });
            },
        });
    };
}

type DidomiEvent = { event: string; listener: (context: any) => void };
declare global { interface Window { didomiEventListeners: Array<DidomiEvent>; } }
