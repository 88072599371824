export const ApiUrls = {
    LOGIN: '/api/v1/front/oauth2/authenticate',
    REFRESH: '/api/v1/front/oauth2/refresh',

    ME: '/api/v1/front/me',

    GET_CURRENT_VERSION: '/api/v1/front/parametrages/versions/current',
    GET_VERSION: (versionId: string) => `/api/v1/front/parametrages/versions/${versionId}`,

    //* QUESTIONS
    QUESTION_DETAILS: (questionId: string) => `api/v1/back/questions/${questionId}`,

    //* QUESTIONS -> PRODUITS
    QUESTION_PRODUIT_LIST: (versionId: string, questionId: string) =>
        `/api/v1/front/parametrages/versions/${versionId}/questions/${questionId}/produits`,
    QUESTION_PRODUIT_GET: (produitRef: string) => `/api/v1/front/produits/${produitRef}`,

    QUESTION_LOCALITY_SEARCH: (versionId: string, questionId: string, zipCode: string) =>
        `/api/v1/front/parametrages/versions/${versionId}/questions/${questionId}/localities/${zipCode}`,
    STORE_LOCALITY_SEARCH: (versionId: string, zipCode: string) => `/api/v1/front/parametrages/versions/${versionId}/stores/localities/${zipCode}`,
    STORE_SEARCH: (versionId: string) => `/api/v1/front/parametrages/versions/${versionId}/stores`,
    //* SYNTHESES
    QUESTION_SYNTHESE_LIST: (versionId: string) =>
        `/api/v1/front/parametrages/versions/${versionId}/questions/syntheses`,

    //* PROJECTS
    PROJECT_LIST: '/api/v1/front/projets',
    GET_PROJECT: (project_id: string) => `/api/v1/front/projets/${project_id}`,
    DELETE_PROJECT: (project_id: string) => `/api/v1/front/projets/${project_id}`,

    CANCEL_CRM_CALLBACK: `/api/v1/front/crmauth/callback/cancel`,

    //* QUOTATIONS
    CREATE_QUOTATIONS: (projetId: string) => `/api/v1/front/projets/${projetId}/quotations`,
    GET_QUOTATION: `/api/v1/front/quotations`,
    GET_QUOTATION_PDF: (projetId: string, quotationId: string) =>
        `/api/v1/front/projets/${projetId}/quotations/${quotationId}/pdf`,

    CHECK_ADDRESS: (address: string) => `/api/v1/front/checkaddress/${address}`,

    GET_IMAGE: (imageId: string) => `/api/v1/front/images/${imageId}`,

    //* CONFIGURATIONS
    GET_CONFIGURATION: (configuration_id: string) => `/api/v1/front/projets/configurations/${configuration_id}`,

    //* CRM
    SET_CRM_INFOS: (project_id: string) => `/api/v1/front/projets/${project_id}/configurations/history`,

    //* PROJECTS
    AUTHENTICATE: '/api/v1/front/crmauth/authenticate',
    CREATE_PROJECT: `/api/v1/front/projets/`,
    UPDATE_PROJECT_METADATA: (project_id: string) => `/api/v1/front/projets/${project_id}`,
    UPDATE_PROJECT_CONFIGURATION: (project_id: string) => `/api/v1/front/projets/${project_id}/configurations/current`,
    UPDATE_PROJECT_REFERENCE: (project_id: string) => `/api/v1/front/projets/${project_id}`,
    IMPORT_PROJECT: `/api/v1/front/projects/import`,
    EXPORT_PROJECT: `/api/v1/front/projects/export`,

    //* PROMOS
    GET_CURRENT_PROMOTION_BANDEAU: '/api/v1/front/promotions/bandeaux/current',
};
