import './CreateOrUpdateProjectDialog.scss';

import { Button, DialogActions } from '@material-ui/core';
import React, { CSSProperties } from 'react';

import { CustomButton } from '../../CustomButton/CustomButton';
import { BaseDialog } from '../BaseDialog/BaseDialog';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { Assets } from '../../../../assets/Assets';
import classNames from 'classnames';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { GestureEvent } from '../../../../../Events/withEvent';

interface CreateOrUpdateProjectDialogRadioButtonProps {
    title: React.ReactNode;
    selected: boolean;
    onBtnClick: () => void;
    withMarginTop?: boolean;
}

const OptionRadioButton = ({
    title,
    selected,
    onBtnClick,
    withMarginTop,
}: CreateOrUpdateProjectDialogRadioButtonProps) => {
    return (
        <Button
            classes={{
                root: classNames({
                    'CreateOrUpdateProjectDialog-radio-btn-root': true,
                    withMarginTop,
                }),
                label: 'CreateOrUpdateProjectDialog-radio-btn-content',
            }}
            onClick={onBtnClick}>
            <div className="CreateOrUpdateProjectDialog-radio-btn-icon">
                {selected ? <Assets.IconRadioCheckedOrange /> : <Assets.IconRadioNormal />}
            </div>
            <div className="CreateOrUpdateProjectDialog-radio-btn-title">{title}</div>
        </Button>
    );
};

export interface CreateOrUpdateProjectDialogProps {
    open: boolean;
    onClose(): void;
    cancelDisabled?: boolean;

    currentProjectName: string;
    updateCurrentProjectAction(setSaving: (saving: boolean) => void): void;
    createNewProjectAction: (text: string, setSaving: (saving: boolean) => void) => void;
}

enum CreateOrUpdateProject {
    CREATE,
    UPDATE,
}

export const CreateOrUpdateProjectDialog = ({
    open,
    onClose,
    cancelDisabled,
    currentProjectName,
    updateCurrentProjectAction,
    createNewProjectAction,
}: CreateOrUpdateProjectDialogProps) => {
    const i18n: IntlShape = useIntl();

    const [option, setOption] = React.useState<CreateOrUpdateProject | undefined>(undefined);
    const [inputText, setInputText] = React.useState<string>('');

    const [saving, setIsSaving] = React.useState<boolean>();
    const setSaving = (saving: boolean) => setIsSaving(saving);

    const isValidateEnabled = (): boolean => {
        return (
            (option === CreateOrUpdateProject.CREATE && Boolean(inputText)) || option === CreateOrUpdateProject.UPDATE
        );
    };

    const onValidateAction = (): void => {
        if (option === CreateOrUpdateProject.UPDATE) {
            updateCurrentProjectAction(setSaving);
        } else if (option === CreateOrUpdateProject.CREATE && inputText) {
            createNewProjectAction(inputText, setSaving);
        }
    };

    const onSubmit = (e?: React.FormEvent | GestureEvent) => {
        e?.preventDefault();
        onValidateAction();
    };

    const onCloseDialog = () => {
        if (!saving) {
            onClose();
        }
    };

    return (
        <BaseDialog
            open={open}
            onClose={cancelDisabled ? undefined : onCloseDialog}
            scroll="body"
            rounded={false}
            title={i18n.formatMessage({ id: 'COMMON_SAVE' })}>
            <form className="CreateOrUpdateProjectDialog-content" onSubmit={onSubmit}>
                <div className="CreateOrUpdateProjectDialog-message">
                    <FormattedMessage id="CreateOrUpdateProjectDialog_Message" />
                </div>
                <div className="CreateOrUpdateProjectDialog-options-container">
                    <OptionRadioButton
                        title={
                            <div className="CreateOrUpdateProjectDialog-save-title">
                                <FormattedMessage
                                    id="CreateOrUpdateProjectDialog_SaveProject"
                                    values={{
                                        name: (
                                            <span className="CreateOrUpdateProjectDialog-save-name">
                                                {currentProjectName}
                                            </span>
                                        ),
                                    }}
                                />
                            </div>
                        }
                        selected={option === CreateOrUpdateProject.UPDATE}
                        onBtnClick={() => setOption(CreateOrUpdateProject.UPDATE)}
                    />
                    <OptionRadioButton
                        title={<FormattedMessage id="CreateOrUpdateProjectDialog_NewProject" />}
                        selected={option === CreateOrUpdateProject.CREATE}
                        onBtnClick={() => setOption(CreateOrUpdateProject.CREATE)}
                        withMarginTop
                    />
                </div>
                {option === CreateOrUpdateProject.CREATE && (
                    <CustomTextField
                        styles={{ root: InputRoot, label: InputLabel, input: Input }}
                        label={<FormattedMessage id="CreateOrUpdateProjectDialog_InputLabel" />}
                        placeholder={i18n.formatMessage({ id: 'CreateProjectDialog_InputPlaceholder' })}
                        value={inputText}
                        onChange={setInputText}
                        autoFocus
                    />
                )}
                <DialogActions classes={{ root: 'CreateOrUpdateProjectDialog-btn-container' }}>
                    <CustomButton
                        title={<FormattedMessage id="COMMON_OK" />}
                        onClick={onSubmit}
                        classes={{ root: 'CreateOrUpdateProjectDialog-btn' }}
                        disabled={!isValidateEnabled() || saving}
                        isBusy={saving}
                    />
                </DialogActions>
            </form>
        </BaseDialog>
    );
};

const InputRoot: CSSProperties = { marginTop: 16, marginLeft: 62, marginRight: 18 };
const InputLabel: CSSProperties = { fontSize: 14, marginBottom: 8, color: '#424448' };
const Input: CSSProperties = { borderRadius: 0, height: 44, padding: 0, paddingLeft: 16, paddingRight: 16 };
