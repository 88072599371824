import TagManager from 'react-gtm-module';

import { EvaluationContext, QuestionContext } from '../../Helpers/QuestionContextHelper';
import { ProduitDto } from '../../Models/Questions/ProduitDto';
import { UserTypeDto } from '../../Models/Users/Me';
import { QuoteStep } from '../../Types/QuoteStep';
import { IRoom } from '../FloorPlan/Models/IRoom';
import { GTMTexts } from './GTMTexts';

interface GTMProduct {
    product: string;
    product_id: string;
    sous_famille_produit: string;
    famille_produit: string;
}
export interface EstimationEvent {
    projetId?: string;
    totalPrice?: number;
    products: Array<GTMProduct>;
    user_mail: string;
}

export enum GtmSaveType {
    Header = 'header',
    AutoSave = 'auto-save',
    DevisEstimatif = 'devis estimatif',
}

export interface GtmStepEvent {
    step?: QuoteStep | string;
    context?: QuestionContext;
    mapTaggage?: MapTaggage;
    totalPrice?: number;
    projetId?: string;
    zipcode?: string;

    //* NEW IN V3
    room_id?: string;
    room_name?: string;
    opening_id?: string;
    opening_name?: string;
    room_item_id?: string;
    room_item_name?: string;
    products: Array<GTMProduct>;
    user_mail: string;
}

export interface PushSaveOrCreateProjectEvent {
    projectId?: string;
    isNewProject?: boolean;
    completedProject?: boolean;
    fromExistingProject?: boolean;
    isAtypique?: boolean;

    location?: GtmSaveType;
    etape?: string;
    piece?: string;
}

export interface RoomTaggage {
    room_id: string;
    type?: string;
    surface?: number;
    etage?: string;
    nom?: string;
}
export interface MapTaggage {
    totalArea?: number;
    roomCount?: number;
    rooms?: Array<RoomTaggage>;
}

export class GTM {
    //* EVENTS
    static pushUserOrigin = (userType?: UserTypeDto) => {
        TagManager.dataLayer({
            dataLayer: {
                pol_entry_point: GTM.toGtmUserType(userType),
                user_status: GTM.toGtmUserStatus(userType),
            },
        });
    };

    static pushCurrentClientId = (client_id?: string) => {
        const dataLayer: Record<string, string> = {};
        if (client_id) {
            dataLayer['client_id'] = client_id;
        }
        if (Object.keys(dataLayer).length) {
            TagManager.dataLayer({ dataLayer });
        }
    };

    static pushEvent = (eventAction: string) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'event',
                eventCategory: 'simulateur-pose',
                eventAction: eventAction,
            },
        });
    };

    //#region //!\ NOT USED
    static pushMyQuoteEvent = () => GTM.pushEvent('mon-devis'); //!\ not used
    //#endregion

    static pushEnregistrerAtypiqueEvent = () => GTM.pushEvent('enregistrer-piece-atypique'); //!\ not used
    static pushClicAtypiqueEvent = () => GTM.pushEvent('clic-piece-atypique'); //!\ not used

    //#region //!\ OBSOLETE IN V3
    static pushStep9LoginEvent = () => GTM.pushEvent('connectez-vous-detail-projet'); //!\ obsolete in v3
    static pushStep9CancelledLoginEvent = () => GTM.pushEvent('connectez-vous-detail-projet-canceled'); //!\ obsolete in v3

    //#endregion

    static pushStMacLoginEvent = () => GTM.pushEvent('connexion-réussie');

    static pushStMacSignUpEvent = () => GTM.pushEvent('inscription-réussie');

    static pushAppointmentEvent = () => GTM.pushEvent('demande-rdv-header');

    static pushDownloadPdfEvent = () => GTM.pushEvent('télécharger-pdf');

    //* NEW IN V3
    static pushAddRoomEvent = () => GTM.pushEvent('ajout-piece');
    static pushAddAmenagementEvent = () => GTM.pushEvent('ajout-amenagement');

    static pushSaveOrCreateProjectEvent = ({
        projectId,
        isNewProject,
        fromExistingProject,
        completedProject,
        location,
        piece,
        etape,
    }: PushSaveOrCreateProjectEvent) => {
        let dataLayer = {
            event: 'event',
            eventCategory: 'simulateur-pose',
            eventLabel: projectId,
            eventAction: isNewProject ? 'création-de-projet' : 'enregistrer',
            location,
            etape,
            piece,
        };
        if (fromExistingProject) {
            dataLayer = { ...dataLayer, eventAction: 'créer-nouveau-projet' };
        }
        if (completedProject) {
            dataLayer = { ...dataLayer, eventAction: 'projet-complet' };
        }
        TagManager.dataLayer({ dataLayer });
    };

    static pushStepViewEvent = ({
        step,
        context = {},
        mapTaggage,
        totalPrice,
        projetId,
        zipcode,
        ...rest
    }: GtmStepEvent) => {
        const messages: Record<string, string> = GTMTexts;
        const evaluations: EvaluationContext = context.evaluations || {};

        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview_virtual',
                pageview_virtual_path: GTM.getValue(messages, `${step}_Path`),
                pageview_virtual_title: GTM.getValue(messages, `${step}_Title`),

                habitation_codepostal: zipcode,
                habitation_amenagement_type: GTM.getValue(
                    evaluations,
                    GTM.getValue(messages, 'habitation_amenagement_type')
                ),
                habitation_amenagement_lieu: GTM.getValue(
                    evaluations,
                    GTM.getValue(messages, 'habitation_amenagement_lieu')
                ),
                habitation_user_type: GTM.getValue(evaluations, GTM.getValue(messages, 'habitation_user_type')),
                habitation_type: GTM.getValue(evaluations, GTM.getValue(messages, 'habitation_type')),
                habitation_compatibilite: '', //TODO: #32407
                plan_surface_m2: mapTaggage?.totalArea,
                plan_nombre_piece: mapTaggage?.roomCount,
                plan_piece: mapTaggage?.rooms,
                estimation_value: totalPrice,
                projet_id: projetId,
                ...rest, //* (Nom et Id de la pièce, ouverture ou amémangement, etc.)
            },
        });
    };

    //* NEW IN V3
    static pushEstimationEvent = ({ projetId, totalPrice, products, user_mail }: EstimationEvent) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview_virtual',
                pageview_virtual_path: '/simulateur-pose/devis',
                pageview_virtual_title: 'simulateur-pose-devis',
                projet_id: projetId,
                total_price: totalPrice,
                products,
                user_mail,
            },
        });
    };

    static getGTMProducts = (rooms: Array<IRoom>): Array<GTMProduct> => {
        const products =
            rooms?.reduce((products, room) => {
                const code = room.questionProduitPrincipal?.produitValue?.code;
                if (code) {
                    if (!products[code]) {
                        products[code] = room.questionProduitPrincipal?.produitValue!;
                    }
                }
                return products;
            }, {} as Record<string, ProduitDto>) ?? {};
        return Object.keys(products).map((code) => ({
            product: products[code].libelle,
            product_id: products[code].code,
            sous_famille_produit:
                products[code].infos_produit.groupe_article.famille_article.sous_famille_article.libelle,
            famille_produit: products[code].infos_produit.groupe_article.famille_article.libelle,
        }));
    };

    //#region //* CONVERTER

    private static getValue = (messages: Record<string, string>, key: string): string => {
        return messages?.[key] || '';
    };

    private static toGtmUserType = (userType?: UserTypeDto): string => {
        if (userType) {
            switch (userType) {
                case UserTypeDto.Customer:
                    return 'client';
                case UserTypeDto.StmacStore:
                    return 'crm';
                case UserTypeDto.BoUserAsCustomer:
                case UserTypeDto.BoUserAsStore:
                    return 'bo';
            }
        }
        return 'client'; //* If unauthenticated, user origin defaults to 'client'.
    };

    private static toGtmUserStatus = (userType?: UserTypeDto): string => {
        return userType ? 'logged' : 'not_logged';
    };

    //#endregion
}
