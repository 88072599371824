import * as Redux from 'redux';
import { AuthService } from '../Services/Authentication/AuthService';
import { AppState } from './PolStore';

import { store } from './PolStore'

export class StoreProvider {

    public static getStore = (): Redux.Store<AppState> => store;

    public static getState = (): AppState => StoreProvider.getStore().getState();
    public static dispatch = (action: any) => StoreProvider.getStore().dispatch(action);

    public static getToken = async (requestBy?: string): Promise<string> => {
        //const authToken: string = await this.getAuthorization(`[ REQUESTED BY ] withAuth for ${url}`);
        return AuthService.getAuthorization();
    }
}