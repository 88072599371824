import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';

import { Logger } from '../../Errors/Logger';
import { ApiError, HttpStatusCode, toApiError } from './ApiError';
import { getAxiosRequestConfig, XHRRequestConfig } from './XHRRequest';

export const paramsSerializer = (params: any): string => {
    const qsOptions: qs.IStringifyOptions = {
        indices: false,
        arrayFormat: 'repeat',
        addQueryPrefix: false,
    };
    return qs.stringify(params, { ...qsOptions });
};

export interface ApiClientOptions {
    readonly baseURL: string;
    readonly refreshUrl: string;
    readonly timeout?: number;
    readonly handleError?: (error: any) => Promise<boolean>;
    readonly refreshAuthorization?: () => Promise<string>;
    readonly getAuthorization?: () => Promise<string>;
}

export class ApiClient {

    private readonly clientOptions: ApiClientOptions;
    private readonly axiosClient: AxiosInstance;

    //* Initialize the ApiClient.
    constructor(options: ApiClientOptions) {

        this.clientOptions = options;
        const { baseURL, timeout = 3000 } = options;

        //* The ApiClient wraps calls to the underlying Axios client.
        this.axiosClient = Axios.create({ baseURL, timeout, paramsSerializer });
        this.initializeResponseRequestInterceptor();
        this.initializeResponseInterceptors();
    }

    /** 💉 inject Authorization in headers */
    private initializeResponseRequestInterceptor = () => {
        this.axiosClient.interceptors.request.use(async (request: XHRRequestConfig) => {
            const { getAuthorization } = this.clientOptions;
            return await getAxiosRequestConfig(request, getAuthorization);
        })
    };

    //#region //* RESPONSE INTERCEPTORS

    private initializeResponseInterceptors = () => {
        const interceptSuccess = (response: any) => Promise.resolve(response.data);
        const interceptError = (error: any) => this.retryCallIfNeeded(error);
        this.axiosClient.interceptors.response.use(interceptSuccess, interceptError);
    };

    //#region //* REFRESH TOKEN IF 401 : Unauthorized

    private async retryCallIfNeeded(error: any): Promise<any> {

        const originalConfig: XHRRequestConfig = error?.response?.config || {};

        const retryPolicy = async (): Promise<any> => {
            const retryCount: number = originalConfig.retryCount ?? 0; //! NOT USED
            const { httpStatusCode }: ApiError = toApiError(error);

            const { refreshUrl, refreshAuthorization } = this.clientOptions;

            const needRefreshToken: boolean =
                httpStatusCode === HttpStatusCode.Unauthorized
                && !originalConfig.isRetryRequest
                && originalConfig.url !== refreshUrl;

            if (needRefreshToken && refreshAuthorization) {
                const authToken: string = await refreshAuthorization?.();

                //* RETRY THE ORIGINAL REQUEST WITH NEW TOKEN
                const config: XHRRequestConfig = {
                    ...originalConfig,
                    headers: {
                        ...originalConfig?.headers,
                        Authorization: authToken
                    },
                    isRetryRequest: true,
                    retryCount: retryCount + 1
                };
                return this.axiosClient(config);
            } else {
                throw error;
            }
        }
        const isCancelled: boolean = Axios.isCancel(error);

        if (isCancelled) {
            //do nothing
        } else {
            try {
                if (!originalConfig.noAuth) {
                    return retryPolicy();
                } else {
                    return Promise.reject(error);
                }
            } catch (retryError) {
                //! retry has failed, but report the original error, i.e. 401
                return Promise.reject(error);
            }
        }
    }

    //#endregion

    //#endregion

    //#region //* REQUEST

    public async get<TResult>(url: string, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.get<TResult>(url, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    public async getRaw<TResult>(url: string, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.get<TResult>(url, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    public async post<TResult>(url: string, body: object, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.post<TResult>(url, body, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    public async put<TResult>(url: string, body: object, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.put<TResult>(url, body, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    public async delete<TResult>(url: string, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.delete<TResult>(url, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    public async patch<TResult>(url: string, body: object, config?: XHRRequestConfig): Promise<TResult> {
        try {
            const response: AxiosResponse<TResult> = await this.axiosClient.patch<TResult>(url, body, config);
            return this.throwErrorIfNeeded<TResult>(response, config);
        } catch (errorResponse) {
            return this.onRequestFailed<TResult>(errorResponse, config);
        }
    }

    //#endregion

    //#region //*  ERROR HANDLER

    private throwErrorIfNeeded<TResult>(response: any, config?: XHRRequestConfig): Promise<TResult> {

        config?.cts?.setCanceler(null);

        if (config?.noCheckError) {
            return Promise.resolve<TResult>(response);
        }
        if (!response.is_success && !Boolean(config?.cts?.canceled)) {

            Logger.logAnalytics({ message: 'POL: apiClient throwErrorIfNeeded', value: { config, response } });

            const result: ApiError = {
                ...response,
                errorCode: response.error_code,
                errorMessage: response.error_message,
                isApiError: true,
            };
            return Promise.reject(result);
        }
        return Promise.resolve(response);
    }

    private onRequestFailed = <TResult>(errorResponse: any, config?: XHRRequestConfig): Promise<TResult> => {
        config?.cts?.setCanceler(null);

        const originRequest = errorResponse?.request;
        const responseTypeIsArrayBuffer = originRequest.responseType === 'arraybuffer';

        const innerError = {
            responseText: responseTypeIsArrayBuffer ? '' : originRequest?.responseText,
            responseURL: originRequest?.responseURL,
            statusText: originRequest?.statusText,
        }
        const error: ApiError = {
            ...toApiError(errorResponse),
            isApiError: true,
            innerError,
        };
        Logger.logAnalytics({ message: 'POL: apiClient onRequestFailed', value: { error } });
        return Promise.reject(error);
    };

    //#endregion
}
