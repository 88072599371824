import { PolStore, PolStoreConfig } from './Configs/PolStore';
import { Constants } from './Constants';
import { Logger } from './Errors/Logger';
import { I18n } from './Locales/I18nService';
import { ApiUrls } from './Models/ApiUrls';
import { Datadog } from './Plugins/Datadog/Datadog';
import { Didomi } from './Plugins/Didomi/Didomi';
import { AnalyticsLogger } from './Services/Analytics/AnalyticsLogger';
import { AuthService } from './Services/Authentication/AuthService';
import { ApiRequest } from './Services/XHR/ApiRequest';
import { NavigationService } from './Web/Services/NavigationService';

export type SetupInitialized = { storeConfig: PolStoreConfig };

export class Setup {
    public static initialize = (): SetupInitialized => {
        console.log(`StMaclou prod : ${Constants.isStMacProd()}`);
        const storeConfig = PolStore.getStore();

        I18n.initialize(storeConfig.store.getState().I18n);
        AnalyticsLogger.initialize();

        //* Initialize Services
        NavigationService.initialize();

        //* Initialize api client
        ApiRequest.initializeApiClient({
            baseURL: Constants.getRoot(),
            refreshUrl: ApiUrls.REFRESH,
            timeout: 1000 * 60, //* 🕐 1 minute;
            refreshAuthorization: (): Promise<string> => AuthService.refreshAuthentication(),
            getAuthorization: (): Promise<string> => AuthService.getAuthorization(),
        });

        //* Initialize GTM & Didomi
        Didomi.initialize();

        //* Initialize Intercom chat
        //window.Intercom?.('boot', { app_id: Constants.INTERCOM_ID }); //!disable Intercom chat

        //* Initialize Datadog
        // Ticket support #41535: Datadog - Désactivation des sessions RUM
        if (Constants.isDatadogRumEnabled()) {
            Datadog.initialize(Constants.ENVIRONMENT, Constants.VERSION_NUMBER);
        }

        Logger.log('🚀🚀🚀🚀🚀 | INIT DONE');

        return { storeConfig };
    };
}
