//@ts-ignore
import loglevelLogger from 'loglevel';
//@ts-ignore
import remote from 'loglevel-plugin-remote';

export class LoglevelLogger {

    public static logger = loglevelLogger;

    public static remote = remote;

    //! loglevelLogger.levels.INFO
    public static setLevel(logLevel: string) {
        loglevelLogger.setLevel(loglevelLogger.levels.INFO);
    }

    public static warn(...msg: any[]) {
        loglevelLogger.warn(msg);
    }

    public static info(...msg: any[]) {
        loglevelLogger.info(msg);
    }

    public static error(...msg: any[]) {
        loglevelLogger.error(msg);
    }
}