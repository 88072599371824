import React, { CSSProperties } from 'react';
import { Constants } from '../../../../Constants';
import { GTM } from '../../../../Plugins/GTM/GTM';
import { BrowserUtils } from '../../../../Utils/BrowserUtils';
import { I18n } from '../../../../Locales/I18nService';
import { Assets } from '../../../assets/Assets';
import { ColorTheme } from '../../../Themes/ColorTheme';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { CustomButton } from './CustomButton';
import { useIsMobile } from '../../../Context/PlatformContext/PlatformContext';

export const onClickAppointment = () => {
    GTM.pushAppointmentEvent();
    const openBookAppointmentUrl = () => BrowserUtils.openUrlInNewTab(Constants.STMAC_BOOK_APPOINTMENT_URL);
    openBookAppointmentUrl();
};

type AppointmentButtonProps = { style?: CSSProperties } & ColorTheme;
export const AppointmentButton = ({ type = 'dark', style }: AppointmentButtonProps) => {
    const isMobile = useIsMobile();
    const isLight = type === 'light';
    const rootStyle: CSSProperties = {
        width: '100%',
        background: isLight ? '#13123A' : '#FFF',
        ...style,
    };
    const contentStyle: CSSProperties = {
        padding: '0px 13px 0px 13px',
    }
    const labelStyle: CSSProperties = {
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
        color: isLight ? '#FFF' : '#13123A',
    };

    return (
        <CustomButton
            title={I18n.get('Appointment_Button_Title')}
            icon={isMobile ? isLight ? <Assets.IconCalendar /> : <Assets.IconCalendarDark /> : undefined}
            styles={{ root: rootStyle, content: contentStyle, label: labelStyle }}
            onClick={onClickAppointment}
        />
    );
};
