
import { LoglevelLogger } from './LoglevelLogger';

import { UserInformations } from './Informations/UserInformations';
import { DeviceInformations } from './Informations/DeviceInformations';

import { LogMessageFields } from './LogMessageModel';
import { LogLevelType } from './LogLevelType';
import { Constants } from '../../Constants';

export class AnalyticsLogger {

    public static initialize(): void {
        LoglevelLogger.setLevel("info");

        LoglevelLogger.logger.methodFactory = () => () => undefined;

        LoglevelLogger.remote.apply(LoglevelLogger.logger, {
            url: Constants.LOGS.elasticSearchUrl,
            // we send a string, but it elasticsearch expects NDJSON format
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Constants.LOGS.apiKey}`,
            },
            // the body must have a newline at the end, but we cannot control that
            // so just add a newline on every message
            format: ({ message }: any) => `${message}\n`,
            capacity: 10,
            level: 'warn',// /!\ not linked with LogLevel inside LogMessageFields
            interval: 1000 * 10, // 10 seconds between each log send to elastic search
        });
    };

    public static send(props: LogMessageFields = {}): void {
        const message = {
            Fields: {
                ...props,
                AppVersion: Constants.VERSION_NUMBER,
                UserInfo: UserInformations.getUserInfo(),
                Device: DeviceInformations.getElasticSearchDeviceInfo(),
            },
            timestamp: new Date().toISOString(),
            LogLevel: props.LogLevel ?? LogLevelType.Information,
        };

        const header = JSON.stringify({ index: { _index: Constants.LOGS.elasticSearchIndex, _type: 'analytics' } });
        const log = `${header}\n${JSON.stringify(message)}\n`;

        LoglevelLogger.warn(log);
    };
}