import React from 'react';

import { Assets } from '../../assets/Assets';

export const Logo = ({ style, onClick, ...rest }: React.SVGAttributes<any>) => {
    return (
        <Assets.Logo
            style={{
                width: 222,
                cursor: onClick ? 'pointer' : 'auto',
                ...style,
            }}
            onClick={onClick}
            {...rest}
        />
    );
};
