import React from 'react';
import { QuotationSummaryDto } from '../../../../Models/Projects/ProjectDto';

import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, styled } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import { DateUtils } from '../../../../Utils/DateUtils';
import { PriceLabel } from '../../../Components/Views/PriceLabel/PriceLabel';
import { useIsMobile } from '../../../Context/PlatformContext/PlatformContext';
import { ThemeRes } from '../../../Themes/ThemeRes';
import { Assets } from '../../../assets/Assets';

export type QuotationListProps = { items?: Array<QuotationSummaryDto>; onClickItem(itemId: string): void };
export const QuotationList = ({ items = [], onClickItem }: QuotationListProps) => {
    const isMobile = useIsMobile();
    return (
        <Table>
            <HeaderContainer>
                <tr>
                    <HeaderCell>
                        <FormattedMessage id="ProjectDetails_Version_CreationDate" />
                    </HeaderCell>
                    <HeaderCell>
                        {isMobile ? (
                            <FormattedMessage id="ProjectDetails_Version_RoomCount_Short" />
                        ) : (
                            <FormattedMessage id="ProjectDetails_Version_RoomCount" />
                        )}
                    </HeaderCell>
                    <HeaderCell>
                        <FormattedMessage id="ProjectDetails_version_Amount" />
                    </HeaderCell>
                </tr>
            </HeaderContainer>
            <tbody>
                {items.map((item) => (
                    <ProjectVersionListItem key={item.id} item={item} onClickItem={onClickItem} />
                ))}
            </tbody>
        </Table>
    );
};

const HeaderCell = styled('th')({
    fontFamily: ThemeRes.Fonts.fontFamilyAvenir,
    fontSize: 16,
    color: 'black',
    padding: '12px 0px 12px 16px',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&:first-child': {
        width: '70%',
    },
    '&:nth-child(2)': {
        width: '25%',
    },
    '&:last-child': {
        padding: 12,
    },
});
const HeaderContainer = styled('thead')({
    textAlign: 'left',
    borderBottom: '1px solid rgba(19, 19, 58, 0.15)',
    borderTop: '1px solid rgba(19, 19, 58, 0.15)',
});
const Table = styled('table')({
    maxWidth: '75%',
    borderCollapse: 'collapse',
    borderSpacing: '0 8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    ['@media (max-width: 800px)']: {
        maxWidth: '100%',
    },
});

export type ProjectVersionListItemProps = { item: QuotationSummaryDto; onClickItem(id: string): void };
export const ProjectVersionListItem = ({ item, onClickItem }: ProjectVersionListItemProps) => {
    const isMobile = useIsMobile();
    return (
        <Row key={item.id} onClick={() => onClickItem(item.configuration_id)}>
            <Cell>
                <DateContainer>
                    <Assets.IconDevis />
                    {DateUtils.formatDateTime(item.created_date, <FormattedMessage id="COMMON_DATE_DEFAULT_VALUE" />)}
                </DateContainer>
            </Cell>
            <Cell>
                <RoomCountContainer>
                    <RoomCount>{item.room_count}</RoomCount>
                    <RoomLabel>
                        <FormattedMessage id="ProjectDetails_RoomCountValue" />
                    </RoomLabel>
                </RoomCountContainer>
            </Cell>
            <Cell>
                <PriceContainer>
                    <PriceLabel price={item.total_amount} variant="noComma" />
                </PriceContainer>
            </Cell>
            <Cell isMobile={isMobile}>
                <Assets.IconArrowRightThin style={{ width: 7, height: 14 }} />
            </Cell>
        </Row>
    );
};
const DateContainer = styled('div')({
    display: 'flex',
    gap: 32,
    alignItems: 'center',
});
const PriceContainer = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 18,
});
const RoomCountContainer = styled('div')({
    display: 'flex',
    gap: 5,
    alignItems: 'center',
});
const RoomCount = styled('div')({
    fontSize: 18,
});
const RoomLabel = styled('div')({
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSans,
    fontSize: 16,
    fontWeight: 400,
});
const ArrowButton = styled(IconButton)({ width: 32, height: 32, background: '#FFFFFF !important' });
const Row = styled('tr')({
    background: 'white',
    color: 'black',
    fontFamily: ThemeRes.Fonts.fontFamilyNunitoSansBold,
    fontSize: 14,
    alignItems: 'center',
    cursor: 'pointer',
    '@media (hover)': {
        '&:hover': {
            border: '1px solid #13123A',
        },
    },
});
type ICellProps = { isMobile?: boolean };
const Cell: React.FC<ICellProps> = ({ isMobile, children }) => {
    return <td className={CellStyle({ isMobile }).root}>{children}</td>;
};
const CellStyle = makeStyles<Theme, ICellProps>(() => ({
    root: ({ isMobile }) => ({
        height: '71px',
        paddingLeft: 15,
        whiteSpace: 'nowrap',
        '&:last-child': {
            paddingLeft: isMobile ? 39 : 32,
            paddingRight: isMobile ? 17 : 24,
            textAlign: 'right',
        },
        '&:first-child': {
            whiteSpace: 'normal',
        },
    }),
}));
