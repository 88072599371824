import { ApiRequest } from '../XHR/ApiRequest';
import { ApiResponse } from '../../Models/ApiData';
import { ApiUrls } from '../../Models/ApiUrls';
import { SetConfigurationDto } from '../../Models/Projects/Configuration';
import {
    ImportProjectsResultResponse,
    PostProjetDto,
    ProjectResponse,
    ProjectsResponse,
    SetProjectReplyResponse,
    SetProjetReferenceDto,
    UpdateProjetDto,
} from '../../Models/Projects/ProjectDto';

export class ProjectsApi {
    public static getProjects = (): Promise<ProjectsResponse> => {
        return ApiRequest.get<ProjectsResponse>(ApiUrls.PROJECT_LIST);
    };

    public static getProject = (projectId: string): Promise<ProjectResponse> => {
        return ApiRequest.get<ProjectResponse>(ApiUrls.GET_PROJECT(projectId));
    };

    public static createProject = (bodyRequest: PostProjetDto): Promise<SetProjectReplyResponse> => {
        return ApiRequest.post<SetProjectReplyResponse>(ApiUrls.CREATE_PROJECT, bodyRequest);
    };

    public static updateProjectMetaData = (
        projectId: string,
        bodyRequest: UpdateProjetDto
    ): Promise<SetProjectReplyResponse> => {
        return ApiRequest.patch<SetProjectReplyResponse>(ApiUrls.UPDATE_PROJECT_METADATA(projectId), bodyRequest);
    };

    public static updateProjectReference = (
        projectId: string,
        bodyRequest: SetProjetReferenceDto
    ): Promise<SetProjectReplyResponse> => {
        return ApiRequest.put<SetProjectReplyResponse>(ApiUrls.UPDATE_PROJECT_REFERENCE(projectId), bodyRequest);
    };

    public static updateProjectConfiguration = (
        projectId: string,
        bodyRequest: SetConfigurationDto
    ): Promise<SetProjectReplyResponse> => {
        return ApiRequest.put<SetProjectReplyResponse>(ApiUrls.UPDATE_PROJECT_CONFIGURATION(projectId), bodyRequest);
    };

    public static deleteProject = (projectId: string): Promise<ApiResponse> => {
        return ApiRequest.delete<ApiResponse>(ApiUrls.DELETE_PROJECT(projectId));
    };

    public static cancelCRMCallback = (): Promise<ApiResponse> => {
        return ApiRequest.post<ApiResponse>(ApiUrls.CANCEL_CRM_CALLBACK, {});
    };

    public static importProject = (
        file: File,
        forceCustomerOverride?: boolean
    ): Promise<ImportProjectsResultResponse> => {
        let bodyRequest = new FormData();
        bodyRequest.append('file', file);

        return ApiRequest.post(ApiUrls.IMPORT_PROJECT, bodyRequest, {
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { force_customer_override: forceCustomerOverride },
        });
    };

    public static exportProject = (projectId: string): Promise<ArrayBuffer> => {
        return ApiRequest.getRaw(ApiUrls.EXPORT_PROJECT, {
            params: { project_ids: projectId },
            noCheckError: true,
            responseType: 'arraybuffer',
        });
    };
}
