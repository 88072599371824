import React from 'react';
import { Logger } from '../Errors/Logger';
import { useWindowLog } from '../Hooks/WindowLog';
import { ProjectDto } from '../Models/Projects/ProjectDto';
import { LogMessageFields } from '../Services/Analytics/LogMessageModel';
import { AuthHelper } from '../Services/Authentication/AuthHelper';
import { EnhancedViewState, useViewState } from '../Web/Hooks/ViewState/ViewState';

export type CurrentProjectState = Partial<{
    project: ProjectDto;
    isUpdateAllowed: boolean;
    isDirty: boolean;
    lastSaveDate: Date;
    lastAutoSave: boolean;
    isSaving: boolean;
}>;

type CurrentProjectContextValue = {
    state?: CurrentProjectState;
    getState: () => CurrentProjectState;
    update: (values: CurrentProjectState) => void;
    reset: () => void;
    isLastSaveWasMoreThan5minAgo: () => boolean;
};
export const CurrentProjectContext = React.createContext<CurrentProjectContextValue>({
    state: {},
    getState: () => ({}),
    update: () => {},
    reset: () => {},
    isLastSaveWasMoreThan5minAgo: () => false,
});
export const useCurrentProject = () => React.useContext(CurrentProjectContext);
export const CurrentProjectProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { state, getState, update: updateState, resetState } = useViewState<CurrentProjectState>({});
    useWindowLog(() => (window.CurrentProjectState = getState));

    const update = (values: Partial<EnhancedViewState<CurrentProjectState>>) => {
        Logger.log('CurrentProjectContext update', values);
        updateState(values, () => (currentProject = getState()));
    };

    const isLastSaveWasMoreThan5minAgo = (): boolean => {
        const state = getState();
        if (state.project) {
            const now = new Date();
            const lastSaveDate = state.lastSaveDate;

            if (state.lastAutoSave) {
                const FIVE_MINUTES = 5 * 60 * 1000;

                //@ts-ignore
                const diff: number = now - lastSaveDate;
                const lastSaveWasMoreThan5minAgo: boolean = diff > FIVE_MINUTES;
                return lastSaveWasMoreThan5minAgo;
            } else {
                const TWO_MINUTES = 2 * 60 * 1000;

                //@ts-ignore
                const diff: number = now - lastSaveDate;
                const lastSaveWasMoreThan2minAgo: boolean = diff > TWO_MINUTES;
                return lastSaveWasMoreThan2minAgo;
            }
        }
        return false;
    };

    return (
        <CurrentProjectContext.Provider
            value={{
                state,
                getState,
                update,
                isLastSaveWasMoreThan5minAgo,
                reset: resetState,
            }}>
            {children}
        </CurrentProjectContext.Provider>
    );
};

/**
 * @deprecated Use CurrentProjectContext instead
 */
let currentProject: CurrentProjectState | undefined;

export const withCurrentProjectLogs = (): Partial<LogMessageFields> => {
    const isCustomer: boolean = AuthHelper.isAuthenticatedAsCustomerUser();
    const isCrmStore: boolean = AuthHelper.isAuthenticatedAsCrmStoreUser();

    return {
        projectId: currentProject?.project?.id,

        //! Dans le cas d'un parcours client
        customerId: isCustomer ? AuthHelper.getMeCustomer()?.id : undefined,

        //! Dans le cas d'une config ouverte à partir du CRM
        CustomerID: isCrmStore ? AuthHelper.getMeCrmStore()?.as_customer.id : undefined,
        StoreId: isCrmStore ? currentProject?.project?.created_by_store?.store.id : undefined,
    };
};
