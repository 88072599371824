import { TokenType } from '../../Models/Authentication/AuthResponse';
import { CustomerPropertiesDto, StmacStorePropertiesDto, UserSummary, UserTypeDto } from '../../Models/Users/Me';
import { AuthService } from './AuthService';

export class AuthHelper {
    /** e.g. "Bearer abfaf83eaeda473fa64c45f42ca11152" */
    public static getAuthorizationFromToken = (token_type: string, token: string) => `${token_type} ${token}`;

    /** Is the authenticated user a customer that come from the STMAC : login by stmac dialog */
    public static isAuthenticated = (): boolean => {
        return Boolean(AuthService.getAuthState().isAuthenticated);
    };

    /** Is the authenticated user a seller that come from the STMAC CRM ? */
    public static isAuthenticatedAsCrmStoreUser = (): boolean => {
        const token = AuthService.getAuthState().token_type;
        return token === TokenType.CrmAXStore;
    };

    /** Is the authenticated user a customer that come from the STMAC : login by stmac dialog */
    public static isAuthenticatedAsCustomerUser = (): boolean => {
        const token = AuthService.getAuthState().token_type;
        return token === TokenType.StmacJWT;
    };

    /** Is the authenticated user a seller (from CRM or test as Seller from BO)? */
    public static isAuthenticatedAsStoreUser = (): boolean => {
        const meInfo = AuthService.getMe()?.data;
        const isBoAsStore = Boolean(meInfo?.bo_user_as_store_properties);
        const isVendeur = AuthHelper.isAuthenticatedAsCrmStoreUser() || isBoAsStore;
        return isVendeur;
    };

    public static isAuthenticatedAsStoreOrBOUser = (): boolean => {
        const meInfo = AuthService.getMe()?.data;
        const isBoUser =
            Boolean(meInfo?.bo_user_as_store_properties) || Boolean(meInfo?.bo_user_as_customer_properties);
        return AuthHelper.isAuthenticatedAsCrmStoreUser() || isBoUser;
    };

    public static getUserType = (): UserTypeDto | undefined => {
        return AuthService.getMe()?.data?.type;
    };

    public static getMeCustomer = (): CustomerPropertiesDto | undefined => {
        return AuthService.getMe()?.data?.customer_properties;
    };

    public static getMeCrmStore = (): StmacStorePropertiesDto | undefined => {
        return AuthService.getMe()?.data?.stmac_store_properties;
    };

    public static isCustomer = () => !AuthHelper.isAuthenticated() || AuthHelper.isAuthenticatedAsCustomerUser();

    //Customer is STAMC USER OR BO USER AS CUSTOMER
    public static isAuthenticatedAsCustomer = (): boolean => {
        const meInfo = AuthService.getMe()?.data;
        const isBoCustomerUser = Boolean(meInfo?.bo_user_as_customer_properties);
        const token = AuthService.getAuthState().token_type;
        return token === TokenType.StmacJWT || isBoCustomerUser;
    };

    public static getMe = (): UserSummary | undefined => {
        const data = AuthService.getMe().data;
        return (
            data?.customer_properties ||
            data?.bo_user_as_customer_properties ||
            data?.stmac_store_properties?.as_customer ||
            data?.bo_user_as_store_properties
        );
    };
}
