import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme, ThemeProvider as MuiThemeProviderOrigin } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { fr as frLocale } from 'date-fns/locale';
import React from 'react';

export type MuiAppProps = { getMuiTheme: () => Theme };

export const MuiApp: React.FC<MuiAppProps> = ({ getMuiTheme, children }) => {
    return (
        <React.Fragment>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <MuiThemeProviderOrigin theme={getMuiTheme()}>{children}</MuiThemeProviderOrigin>
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
};
