import { Action, LocationState } from "history";

import { browserHistory } from "../../Configs/browserHistory";
import { AppRoutes } from "../../Configs/AppRoutes";
import { Constants } from "../../Constants";

export interface GoToRouteParameter {
  readonly route: string,
  readonly replace?: boolean;
  readonly state?: any;
  readonly search?: string;
}

//* Generic detail route
const getDetailsRoute = (
  paramKey: string,
  paramValue: string,
  detailsRoute: string
): string => `${detailsRoute}`.replace(`:${paramKey}`, paramValue);

export class NavigationService {

  public static initialize(): void {
    browserHistory.listen((location: LocationState, action: Action) => {
      //console.log("history listen", { location, action });
      //* can be used to reset state if url changed
    });
  }

  public static goTo = (goToRouteParameter: GoToRouteParameter): void => {
    const { route, replace, state, search } = goToRouteParameter;
    if (replace) {
      browserHistory.replace({ pathname: route, state, search });
    } else {
      browserHistory.push({ pathname: route, state, search });
    }
  };

  public replace = (newUrl: string): void => browserHistory.replace({ pathname: newUrl });

  public static replaceParams = (queryParams: URLSearchParams): void => {
    browserHistory.replace({ search: queryParams?.toString() });
  }

  public goBack = () => browserHistory.goBack();

  public static goToHome = (): void => NavigationService.goTo({ route: Constants.STMAC_URL });

  public static goToQuote = (configId?: string, params?: URLSearchParams): void =>
    NavigationService.goTo({
      route: AppRoutes.DEFAULT_ROUTE,
      state: { configId },
      search: params?.toString()
    });

  public static goToMyProjects = (params: URLSearchParams): void => {
    NavigationService.goTo({ route: AppRoutes.MY_PROJECTS, search: params?.toString() });
  }

  public static goToProjectDetails = (projectId: string, params: URLSearchParams): void => {
    NavigationService.goTo({ route: NavigationService.getProjectDetails(projectId), search: params?.toString() });
  }

  //* DETAILS ROUTES
  public static getProjectDetails = (projectId: string): string =>
    getDetailsRoute("projectId", projectId, AppRoutes.PROJECT_DETAILS);
}
