export type ExportProps = { url: string; auth: string; }

export enum ExportFileType {
    PDF = '.pdf',
    EXCEL = '.xlsx',
    ZIP = '.zip',
}

export class ExportUtils {
    // static getExportUrlWithParams(
    //     { url, auth }: ExportProps,
    //     params?: { [key: string]: string }
    // ): string {
    //     let resultString = `${url}?Authorization=${auth}`;

    //     for (let key in params) {
    //         resultString += `&${key}=${params[key]}`;
    //     }

    //     return resultString;
    // }

    // static downloadFileFromUrl(url: string) {
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', '');
    //     link.click();
    // }

    static saveToFile(data: ArrayBuffer, fileName: string, exportFileType: ExportFileType) {
        const link = document.createElement('a');
        const blob = new Blob([data], {
            type: ExportUtils.toFileType(exportFileType),
        });
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', `${fileName}${exportFileType}`);
        link.click();
    }

    private static toFileType(exportFileType: ExportFileType) {
        switch (exportFileType) {
            case ExportFileType.PDF:
                return 'application/pdf';
            case ExportFileType.EXCEL:
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
            case ExportFileType.ZIP:
                return 'application/zip;';
    
            default:
                throw new Error();
        }
    }
}
