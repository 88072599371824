import { createTheme, Theme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

// $primaryColor: #0f0e2d;
// $secondaryColor: #e5a033;

export class ThemeMuiProvider {
    public static provideTheme = (): Theme => {
        const palette = createPalette({
            primary: { main: '#0f0e2d' },
            secondary: { main: '#e5a033' },
            type: 'light',
        });

        const typography = createTypography(palette, {
            button: { textTransform: 'none' },
        });

        return createTheme({
            palette,
            typography,
            overrides: {
                MuiButton: { root: { textTransform: 'none' } },
            },
        });
    };
}
