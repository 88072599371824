import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StringKey } from './fr';
import { StringsState } from './i18nReducer';

let messages: Record<string, string> = {};

export class I18n {
    public static initialize = (i18nState: StringsState) => (messages = i18nState.messages);

    public static get = (id: StringKey): string => messages[id] || id;
    public static getValue = (id: StringKey): string => messages[id];

    //* placeholders is type of { [key: string]: string }
    public static format = (id: string, placeholders: Record<string, string | undefined> = {}): string => {
        let message = messages[id] || '';
        for (const propertyName in placeholders) {
            const re = new RegExp(`{${propertyName}}`, 'gm');
            message = message.replace(re, placeholders[propertyName]!);
        }
        return message;
    };
}

type MessageProps = { id: StringKey; values?: Record<string, ReactNode> };
export const Message = ({ id, values = {} }: MessageProps) => {
    return <FormattedMessage id={id} values={values} />;
};

export const useMessage = (id: StringKey): string => {
    const messages = useIntl().messages;
    return String(messages[id]);
};
