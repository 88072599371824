import { AnyAction, MiddlewareAPI } from 'redux';

import { TokenType } from '../../Models/Authentication/AuthResponse';
import { AuthActionType, SetAuthenticatedAction } from '../Authentication/typings/AuthenticationActionTypes';
import { MeActions } from './MeActions';
import { MeService } from './MeService';

export const MeMiddleware =
    ({ dispatch }: MiddlewareAPI) =>
        (next: any) =>
            (action: AnyAction) => {
                if (action) {
                    switch (action.type) {
                        case AuthActionType.SET_AUTHENTICATED:
                            const res = next(action);
                            const setAuthAction = action as SetAuthenticatedAction;
                            if (
                                setAuthAction.payload.isAuthenticated &&
                                setAuthAction.payload.authorization.token_type !== TokenType.StmacJWT
                            ) {
                                MeService.getMyInfo().catch((err: any) => err);
                            } else {
                                dispatch(MeActions.setInfo(undefined));
                            }
                            return res;
                        default:
                            break;
                    }
                }
                return next(action);
            };
