import { toast, ToastId } from 'react-toastify';
import { StringKey } from '../../Locales/fr';
import { I18n } from '../../Locales/I18nService';

import { CustomToast, CustomToastType } from '../Components/Views/CustomToast/CustomToast';

export interface ToastActionOptions {
    actionTitle: string;
    action?: () => void;
    hideToastActionTrigger?: () => void;
}

export interface DownloadOptions {
    fileName: string;
    content: string;
    success?: boolean;
    failed?: boolean;
}

export interface ToastParams {
    title?: string;
    content: string;
    actionOptions?: ToastActionOptions;
    duration?: number;
    copyContent?: string;
}
export class Toast {
    public static showSuccess(toastParams: ToastParams): ToastId {
        const { title, content } = toastParams;
        return toast.success(CustomToast({ type: CustomToastType.SUCCESS, title, content }));
    }

    public static showError(toastParams: ToastParams): ToastId {
        const { title, content, actionOptions, duration } = toastParams;
        return toast.error(
            CustomToast({
                type: CustomToastType.ERROR,
                title,
                content,
                actionOptions,
            }),
            { autoClose: duration }
        );
    }

    public static showInfo(toastParams: ToastParams): ToastId {
        const { title, content, copyContent, actionOptions, duration } = toastParams;
        return toast.error(
            CustomToast({
                type: CustomToastType.INFO,
                title,
                content,
                copyContent,
                actionOptions,
            }),
            { autoClose: duration }
        );
    }

    //* CUSTOM DOWNLOAD TOAST
    public static showDownloadInfo = (downloadOptions: DownloadOptions): ToastId => '';
    public static updateDownloadInfo = (toastId: ToastId, downloadOptions: DownloadOptions): ToastId => '';

    public static dismiss = (toastId: ToastId) => toast.dismiss(toastId);

    public static success = (messageId: StringKey) => Toast.showSuccess({ content: I18n.get(messageId) });
    public static error = (messageId: StringKey) => Toast.showError({ content: I18n.get(messageId) });

    public static notImplemented = () => {
        return toast.success(
            CustomToast({
                type: CustomToastType.INFO,
                title: 'Not implemented',
                content: 'Revenez la prochaine fois',
            })
        );
    };
}
