import React from 'react';

import { CustomLoader } from '../CustomLoader';
import { Logo } from '../Logo';
import { styled } from '@material-ui/styles';

export const LoadingPage = () => {
    return (
        <Container>
            <Logo style={{ marginBottom: '24px' }} />
            <CustomLoader darkColor />
        </Container>
    );
};

const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#ffffff',
});
