/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { Constants } from '../../../Constants';
import { Logger } from '../../../Errors/Logger';
import { TokenType } from '../../../Models/Authentication/AuthResponse';
import { SetProjetReferenceDto } from '../../../Models/Projects/ProjectDto';
import { StmacLoginMessage, StmacLoginMessages } from '../../../Models/StmacPostMessages/StmacLoginMessages';
import { StmacPostMessage } from '../../../Models/StmacPostMessages/StmacPostMessage';
import {
    SaveProjectResponseMessage,
    StmacSaveProjectMessage,
} from '../../../Models/StmacPostMessages/StmacSaveProjectMessage';
import { UserTypeDto } from '../../../Models/Users/Me';
import { ProjectsApi } from '../../../Services/Api/ProjectsApi';
import { AuthService } from '../../../Services/Authentication/AuthService';
import { MeService } from '../../../Services/Me/MeService';

import { Toast } from '../../Services/ToastService';

import { I18n } from '../../../Locales/I18nService';
import { LogLevelType } from '../../../Services/Analytics/LogLevelType';

export type StmacLoginViewGateControllerParameters = { isAuthenticated: boolean; isAuthenticatedFromUrl?: boolean };

type RequestGetUser = { postRequest?: any; result?: any };
export const useStmacLoginViewGateController = ({
    isAuthenticated,
    isAuthenticatedFromUrl,
}: StmacLoginViewGateControllerParameters) => {
    const [isStMacLoginInitialized, setIsStMacLoginInitialized] = React.useState(false);
    const [isStMacLoginTimout, setStMacLoginTimout] = React.useState(false);
    const authReady = isAuthenticated || isStMacLoginInitialized;
    const requestGetUser = React.useRef<RequestGetUser>(); //other way to fix this : remove listener after request get:user

    const setUserConnectedFromMessage = async (message: any) => {
        const loginMessage: StmacLoginMessage = message as StmacLoginMessage;
        await AuthService.setAuthorizationToken({
            access_token: loginMessage.client.token,
            refresh_token: '',
            token_type: TokenType.StmacJWT,
        });
        const meData = {
            type: UserTypeDto.Customer,
            customer_properties: {
                id: loginMessage.client?.id,
                first_name: loginMessage.client?.first_name,
                last_name: loginMessage.client?.last_name,
                email: loginMessage.client?.email,
            },
        };
        await MeService.setMyInfo(meData);

        Logger.logAnalytics({
            level: LogLevelType.Information,
            message: 'POL: setMyinfo',
            data: meData,
        });

        setIsStMacLoginInitialized(true);
    };

    const handleMessage = async (message: StmacPostMessage) => {
        console.log('POL: handle message', {
            message,
            isAuthenticated,
            isPostMessageReady: StmacLoginMessages.isPostMessageReady(message.type),
            isPostMessageResGetUser: StmacLoginMessages.isPostMessageResGetUser(message.type),
            isPostMessageUser: StmacLoginMessages.isPostMessageUserAuthenticated(message.type),
            requestGetUser: requestGetUser.current,
        });

        if (StmacLoginMessages.isPostMessageReady(message.type) && !requestGetUser.current) {
            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: 'POL: receive message embed:ready',
                data: message,
            });

            const iframe = document.getElementById(Constants.STMAC_FRAME_ID) as any;
            const postMessageToSend = StmacLoginMessages.getCurrentUser();
            console.log('POL: iframe will send message', { iframe, message: postMessageToSend });
            //* can request logged user
            if (iframe) {
                const targetOrigin = Constants.STMAC_URL;
                iframe.contentWindow.postMessage(postMessageToSend, targetOrigin);
                requestGetUser.current = { postRequest: postMessageToSend };
                Logger.logAnalytics({
                    level: LogLevelType.Information,
                    message: 'POL: send message get_user',
                    data: postMessageToSend,
                });
            }
        }

        if (StmacLoginMessages.isPostMessageResGetUser(message.type)) {
            const hasUserConnected = Boolean((message as any).client?.id);
            requestGetUser.current = { ...requestGetUser.current, result: message };

            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: 'POL: receive message res:get_user',
                data: { hasUserConnected },
            });
            if (hasUserConnected) {
                setUserConnectedFromMessage(message);
            } else {
                AuthService.logout('not clientId from res:get_user');
                setIsStMacLoginInitialized(true);
            }
        }

        if (StmacLoginMessages.isPostMessageUserAuthenticated(message.type)) {
            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: 'POL: receive message user_authenticated',
                data: message,
            });
            setUserConnectedFromMessage(message);
        }

        if (StmacSaveProjectMessage.isPostMessageResSaveProject(message.type)) {
            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: "POL: Retour du PostMessage d'enregistrement projet",
                data: message,
                body: JSON.stringify(message),
            });
            const saveResponseMessage: SaveProjectResponseMessage = message as SaveProjectResponseMessage;
            const originProjectId = StmacSaveProjectMessage.getOriginProjectIdFromRequestId(message.tid);
            if (originProjectId && Boolean(saveResponseMessage.project_id)) {
                const setProjetReferenceDto: SetProjetReferenceDto = {
                    projet_reference_id: saveResponseMessage.project_id,
                };
                ProjectsApi.updateProjectReference(originProjectId, setProjetReferenceDto);

                const data = { projectId: originProjectId, bodyRequest: setProjetReferenceDto };
                Logger.logAnalytics({
                    level: LogLevelType.Information,
                    message: 'POL call updateProjectReference',
                    data,
                    body: JSON.stringify(data),
                });
            }
        }
    };

    const onReceiveMessage = React.useCallback(
        (event: MessageEvent) => {
            const devtoolsSources: Array<string> = ['react-devtools-bridge', 'react-devtools-content-script'];
            const withLog: boolean = !devtoolsSources.includes(event?.data.source);
            if (event.origin === Constants.STMAC_URL) {
                const messageData: StmacLoginMessage = event.data;
                withLog && Logger.log('POL receive message from ' + event.origin, event.data);
                handleMessage(messageData);
            }
        },
        [handleMessage]
    );

    const subscribeOnMessage = () => window.addEventListener('message', onReceiveMessage);
    const unSubscribeOnMessage = () => window.removeEventListener('message', onReceiveMessage);

    React.useEffect(() => {
        if (!isAuthenticatedFromUrl && !authReady) {
            const timer = setTimeout(() => {
                const toastMessage = I18n.get('ERROR_AUTHENTICATION_IFRAME_TIMEOUT');
                Toast.showError({ content: toastMessage });
                setStMacLoginTimout(true);

                Logger.logAnalytics({
                    level: LogLevelType.Error,
                    message: "POL: Timout d'authentification atteint",
                    data: { timeout: Constants.STMAC_IFRAME_RESPONSE_TIMEOUT_MS, message: toastMessage },
                });
            }, Constants.STMAC_IFRAME_RESPONSE_TIMEOUT_MS);

            return () => clearTimeout(timer);
        }
    }, [authReady]);
    return { authReady, isStMacLoginTimout, subscribeOnMessage, unSubscribeOnMessage };
};
