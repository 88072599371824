import { MeDto, UserTypeDto } from '../Models/Users/Me';
import { I18n } from '../Locales/I18nService';

export type UserInfoType = { first_name: string; last_name: string; };

export class UserUtils {
    static getFullName(user?: MeDto): string {
        switch (user?.type) {
            case UserTypeDto.Customer:
                return UserUtils.toUserInfo(user.customer_properties);
            case UserTypeDto.StmacStore:
                return UserUtils.toUserInfo(user.stmac_store_properties?.as_customer);
            case UserTypeDto.BoUserAsStore:
                return UserUtils.toUserInfo(user.bo_user_as_store_properties, 'TEST_STORE_USER_FULLNAME');
            case UserTypeDto.BoUserAsCustomer:
                return UserUtils.toUserInfo(user.bo_user_as_customer_properties, 'TEST_CUSTOMER_USER_FULLNAME');
        }

        return '';
    }

    public static toUserInfo(user?: UserInfoType, customIntlKey?: string): string {
        return I18n.format(customIntlKey ?? 'COMMON_USER_FULLNAME', {
            firstName: user?.first_name,
            lastName: user?.last_name,
        });
    }
}
