import { styled } from '@material-ui/styles';
import React from 'react';
import { Message } from '../../../../Locales/I18nService';
import { BaseDialog } from '../../../../Plugins/FloorPlan/Widgets/BaseDialog';
import { ButtonVariant, CustomButton } from '../../../Components/Views/CustomButton/CustomButton';
import { InputType } from '../../../Components/Views/CustomTextField/CustomTextField';
import { InputTextField } from './InputTextField';

export interface EditInputDialogProps {
    open: boolean;
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    description: React.ReactNode;
    initialValue?: string;
    placeholder?: string;
    onValidate: (
        input: string,
        setValidating: (validating: boolean) => void,
        setError: (validating: boolean) => void
    ) => void;
    onClose?: VoidFunction;
}

export const EditInputDialog: React.FunctionComponent<EditInputDialogProps> = ({
    open,
    title,
    subtitle,
    description,
    initialValue,
    placeholder,
    onClose,
    onValidate,
}: EditInputDialogProps) => {
    const [input, setInput] = React.useState<string>(initialValue ?? '');
    const [error, setError] = React.useState(false);
    const [validating, setValidating] = React.useState(false);

    const onClickValidate = () => onValidate(input, setValidating, setError);

    return (
        <BaseDialog title={title} onClose={onClose}>
            <InputTextField
                description={description}
                placeholder={placeholder}
                type={InputType.Text}
                error={error}
                value={input}
                disabled={validating}
                onChange={setInput}
            />
            <ButtonContainer>
                <CustomButton
                    title={<Message id="COMMON_VALIDATE" />}
                    variant={ButtonVariant.Blue}
                    isBusy={validating}
                    onClick={onClickValidate}
                />
            </ButtonContainer>
        </BaseDialog>
    );
};

const ButtonContainer = styled('div')({ display: 'flex', justifyContent: 'flex-end', marginTop: 24 });
