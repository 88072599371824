/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Constants } from '../../../Constants';
import { Logger } from '../../../Errors/Logger';
import { TokenType } from '../../../Models/Authentication/AuthResponse';
import { StmacLoginMessage, StmacLoginMessages } from '../../../Models/StmacPostMessages/StmacLoginMessages';
import { UserTypeDto } from '../../../Models/Users/Me';
import { GTM } from '../../../Plugins/GTM/GTM';
import { LogLevelType } from '../../../Services/Analytics/LogLevelType';
import { AuthService } from '../../../Services/Authentication/AuthService';
import { MeService } from '../../../Services/Me/MeService';

export type UseStmacLoginControllerParams = { onConnected?: VoidFunction };

//TODO: PGU voir pour mutualiser avec StmacViewController
export const useStmacLoginDialogController = ({ onConnected }: UseStmacLoginControllerParams = {}) => {
    const handleMessage = async (message: StmacLoginMessage) => {
        Logger.log('POL: LoginDialog handleMessage', {
            message,
            isPostMessageUser: StmacLoginMessages.isPostMessageUserAuthenticated(message.type),
        });

        if (StmacLoginMessages.isPostMessageUserAuthenticated(message.type)) {
            GTM.pushStMacLoginEvent();

            await AuthService.setAuthorizationToken({
                access_token: message.client.token,
                refresh_token: '',
                token_type: TokenType.StmacJWT,
            });

            const dataMyInfo = {
                type: UserTypeDto.Customer,
                customer_properties: {
                    id: message.client?.id,
                    first_name: message.client?.first_name,
                    last_name: message.client?.last_name,
                    email: message.client?.email,
                },
            };

            await MeService.setMyInfo(dataMyInfo);

            Logger.logAnalytics({
                level: LogLevelType.Information,
                message: 'POL: Retour du PostMessage de la connexion',
                data: dataMyInfo,
            });
            onConnected?.();
        } else if (StmacLoginMessages.isPostMessageResSetUser(message.type)) {
            GTM.pushStMacSignUpEvent();
        }
    };

    const onReceiveMessage = React.useCallback(
        (event: any) => {
            const devtoolsSources: Array<string> = ['react-devtools-bridge', 'react-devtools-content-script'];
            const withLog: boolean = !devtoolsSources.includes(event?.data.source);

            if (event.origin === Constants.STMAC_URL) {
                const messageData: StmacLoginMessage = event.data;
                withLog && Logger.log('POL Dialog onReceiveMessage from ' + event.origin, event.data);
                handleMessage(messageData);
            }
        },
        [handleMessage]
    );

    React.useEffect(() => {
        Logger.logAnalytics({
            level: LogLevelType.Information,
            message: "POL: Ouverture de l'iframe de dialog connexion",
        });
        window.addEventListener('message', onReceiveMessage);
        return () => window.removeEventListener('message', onReceiveMessage);
    }, []);
};
