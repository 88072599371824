import React from 'react';

import { DialogService } from '../../Services/DialogService';

export const CustomDialogContainer = () => {
    const [dialog, setDialog] = React.useState<React.ReactNode>();

    const showDialog = React.useCallback((dialog: React.ReactNode) => setDialog(dialog), []);
    const destroyDialog = React.useCallback(() => setDialog(undefined), []);

    React.useEffect(() => {
        DialogService.setDialogContainer({ showDialog, destroyDialog });
    }, [showDialog, destroyDialog]);

    // const dialogService = React.useMemo(
    //   () => IoC.get<IDialogService>(DialogServiceName),
    //   []
    // );

    // React.useEffect(() => {
    //   dialogService.setDialogContainer({ showDialog, destroyDialog });
    // }, [showDialog, destroyDialog, dialogService]);

    return <React.Fragment>{dialog}</React.Fragment>;
};
