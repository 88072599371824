import './CreateProjectDialog.scss';

import { DialogActions } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { CustomButton } from '../../CustomButton/CustomButton';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { BaseDialog } from '../BaseDialog/BaseDialog';
import { GestureEvent } from '../../../../../Events/withEvent';

export interface CreateProjectDialogProps {
    open: boolean;
    validateAction: (text: string, setSaving: (saving: boolean) => void) => void;
    onClose(): void;
    cancelDisabled?: boolean;
}

export const CreateProjectDialog = ({ open, validateAction, onClose, cancelDisabled }: CreateProjectDialogProps) => {
    const i18n: IntlShape = useIntl();

    const [inputText, setInputText] = React.useState('');

    const [saving, setIsSaving] = React.useState<boolean>();
    const setSaving = (saving: boolean) => setIsSaving(saving);

    const onSubmit = (e?: React.FormEvent | GestureEvent) => {
        e?.preventDefault();
        if (inputText) {
            validateAction(inputText, setSaving);
        }
    };

    const onCloseDialog = () => {
        if (!saving) {
            onClose();
        }
    };

    return (
        <BaseDialog
            open={open}
            onClose={cancelDisabled ? undefined : onCloseDialog}
            scroll="body"
            rounded={false}
            title={i18n.formatMessage({ id: 'COMMON_SAVE' })}>
            <form className="CreateProjectDialog-content" onSubmit={onSubmit}>
                <CustomTextField
                    styles={{ root: InputRoot, input: Input }}
                    label={<FormattedMessage id="CreateProjectDialog_InputLabel" />}
                    placeholder={i18n.formatMessage({ id: 'CreateProjectDialog_InputPlaceholder' })}
                    value={inputText}
                    onChange={setInputText}
                    autoFocus
                />
                <DialogActions classes={{ root: 'CreateProjectDialog-btn-container' }}>
                    <CustomButton
                        title={<FormattedMessage id="COMMON_VALIDATE" />}
                        onClick={onSubmit}
                        classes={{ root: 'CreateProjectDialog-btn' }}
                        disabled={!inputText || saving}
                        isBusy={saving}
                    />
                </DialogActions>
            </form>
        </BaseDialog>
    );
};

const InputRoot: CSSProperties = { marginTop: 28, marginLeft: 18, marginRight: 18 };
const Input: CSSProperties = { borderRadius: 0, height: 44, padding: 0, paddingLeft: 16, paddingRight: 16 };
