import React, { useEffect, useState } from 'react';
import { Constants } from '../../../../../Constants';
import { AuthService } from '../../../../../Services/Authentication/AuthService';
import { RouteQueryParams } from '../../../../../Types/RouteParams';
import { useQueryCore } from '../../../../Hooks/URL/URLUtils';
import { Logger } from '../../../../../Errors/Logger';

/** If the access_token is passed via url this gate change the current token. This need to be call before EssentialsActionsGate in the render tree */
export function PolQueryAuthenticationGate({ children }: { children: React.ReactChild }) {
    const { access_token, refresh_token, token_type, remove_token } = useQueryCore<RouteQueryParams>(window.location);
    const [initialized, setInitialized] = useState(false);

    const logout = async (): Promise<void> => await AuthService.logout('Requested from BO');

    useEffect(() => {
        if (remove_token === Constants.BO_CLEAR_TOKEN) {
            logout();
            setInitialized(true);
        } else if (access_token) {
            AuthService.setAuthorizationToken({ access_token, refresh_token, token_type });
            //Remove token from url to prevent future refresh bug.
            let params = new URLSearchParams(window.location.search);
            params.delete('access_token');
            params.delete('refresh_token');
            params.delete('token_type');
            Logger.log('Param', params.toString());
            window.history.replaceState(null, document.title, window.location.pathname + '?' + params.toString());
            setInitialized(true);
        }
    }, [access_token, refresh_token, token_type, remove_token]);

    const ready = (!access_token && !remove_token) || initialized;

    if (ready) {
        return <>{children}</>;
    }

    return null;
}
