import { CreatedByTypeDto } from '../Models/Projects/ProjectDto';
import { UserTypeDto } from '../Models/Users/Me';
import { MeState } from '../Services/Me/MeReducer';

export type IsAllowedToUpdateParams = { createdByType?: CreatedByTypeDto; userType?: UserTypeDto };
export type IsAllowedToUpdateMetaDataParams = { me: MeState; createdByStoreId?: string; createdByCustomerID?: string };

export class ProjectHelper {
    public static isAllowedToUpdate = ({ createdByType, userType }: IsAllowedToUpdateParams): boolean => {
        switch (createdByType) {
            case CreatedByTypeDto.Customer:
                return userType === UserTypeDto.Customer || userType === UserTypeDto.BoUserAsCustomer;
            case CreatedByTypeDto.Store:
                return userType === UserTypeDto.StmacStore || userType === UserTypeDto.BoUserAsStore;
        }
        return true;
    };

    public static isAllowedToUpdateMetaData = ({
        me,
        createdByStoreId,
        createdByCustomerID,
    }: IsAllowedToUpdateMetaDataParams): boolean => {
        const customerID = me?.data?.customer_properties?.id ?? me.data?.stmac_store_properties?.as_customer.id;
        const storeId = me?.data?.stmac_store_properties?.id;
        return createdByCustomerID === customerID || createdByStoreId === storeId;
    };
}
